import { Button } from "@cmsgov/ds-medicare-gov";
import React from "react";
import { useTranslate } from "../helpers";
import { PrintIcon } from "./SvgIcons";
import { sendPrintAnalyticsEvent } from "../app/contexts/Analytics/helpers";

interface PrintButtonProps {
  onClick?: () => void;
  className?: string;
}

export const printPage = (): void => {
  // For some reason, window.print is not working in Safari even though it's a supported function
  if (!document.execCommand("print", false, undefined)) {
    window.print();
  }
};

const PrintButton: React.FunctionComponent<PrintButtonProps> = ({
  children,
  onClick,
  className,
  ...props
}) => {
  const t = useTranslate();

  return (
    <Button
      variation="ghost"
      onClick={
        onClick ||
        ((): void => {
          sendPrintAnalyticsEvent();
          printPage();
        })
      }
      className={`PrintButton mct-hide-print ${className}`}
      {...props}
    >
      <PrintIcon />
      <div>{children || t("print")}</div>
    </Button>
  );
};

export default PrintButton;
