import { DrugPackageUnit, DrugDosage, PrescriptionDrug } from "../@types";
import pluralize from "pluralize";
import { stripParens } from "./objectUtilities";
import {
  AnalyticsActions,
  AnalyticsActionType,
} from "../app/contexts/Analytics/types";
import { Dispatch } from "react";

export const uppercaseFirst = (str: string | undefined): string => {
  if (!str) return "";

  return str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase();
};

export const getDrugFormKey = (form: string): string => {
  // Until fixed by API, sometimes a form may be an integer
  form = String(form);

  const prefix = "DRUG_FORM_";

  if (!form.includes(prefix)) return "na";

  const translationKey = form.substring(prefix.length).toLowerCase();

  return `drug_form.${translationKey}`;
};

export const getDrugPackageKey = (type: string): string => {
  // Until fixed by API, sometimes a type may be an integer
  type = String(type);

  const prefix = "PACKAGE_TYPE_";

  if (!type.includes(prefix)) return "na";

  const translationKey = type.substring(prefix.length).toLowerCase();

  return `package_type.${translationKey}`;
};

export const displayDrug = (
  t: (key: string) => string,
  {
    name = "",
    strength = "",
    strength_units = "",
    form = "",
  }: {
    form: string;
    name: string;
    strength: string;
    strength_units: string;
  }
): string => {
  const drugName = uppercaseFirst(name);

  const drugForm = t(getDrugFormKey(form));

  return `${drugName} ${strength}${strength_units.toLowerCase()} ${drugForm}`.trim();
};

export const displayPackage = (
  t: (key: string) => string,
  {
    form = "",
    package_quantity = "",
    package_size = "",
    package_type = "",
    package_units = "",
  }: Pick<
    PrescriptionDrug,
    | "form"
    | "package_quantity"
    | "package_size"
    | "package_type"
    | "package_units"
  >
): string => {
  const packString = t(getDrugPackageKey(package_type));
  const defaultString =
    `${package_size}${package_units} ${packString}`.toLowerCase();

  switch (package_units) {
    case DrugPackageUnit.EA: {
      const f = pluralize(t(getDrugFormKey(form)), Number(package_size));
      let eaReturn = `${uppercaseFirst(packString)} ${t(
        "of"
      )} ${package_size} ${f}`;

      if (Number(package_quantity) > 1) {
        eaReturn += ` (${t("sold_in_pack_of")} ${package_quantity})`;
      }

      return eaReturn;
    }
    case DrugPackageUnit.ML:
    case DrugPackageUnit.GM: {
      if (Number(package_quantity) > 1) {
        return `${defaultString} (${t("sold_in_pack_of")} ${package_quantity})`;
      } else {
        return defaultString;
      }
    }
    default: {
      return defaultString;
    }
  }
};

export const sortByFormAndStrength = (a: DrugDosage, b: DrugDosage): number => {
  if (a.form === b.form) {
    const aStrengthStr: string = stripParens(a.strength);
    const bStrengthStr: string = stripParens(b.strength);
    let aStrength: string | number = parseFloat(aStrengthStr);
    let bStrength: string | number = parseFloat(bStrengthStr);

    if (Number.isNaN(aStrength) || Number.isNaN(bStrength)) {
      aStrength = aStrengthStr;
      bStrength = bStrengthStr;
    }

    if (aStrength > bStrength) {
      return 1;
    }
    if (aStrength < bStrength) {
      return -1;
    }
    return 0;
  }
  if (a.form > b.form) {
    return 1;
  }
  return -1;
};

export const sendDrugConfirmationTealiumEvent = (
  prescriptions: PrescriptionDrug[],
  dispatch: Dispatch<AnalyticsActions>
): void => {
  const drugInfo = prescriptions
    .map(prescription => prescription.name)
    .join("|");
  const label = `${prescriptions.length}|${drugInfo}`;

  dispatch({
    type: AnalyticsActionType.SEND_TEALIUM_EVENT,
    settings: {
      event_action: "Find Plans - Confirm Drugs",
      event_label: label,
    },
  });
};

export const drugsAreDuplicates = (
  drug: PrescriptionDrug,
  drug2: PrescriptionDrug
): boolean =>
  String(drug.ndc) === String(drug2.ndc) &&
  String(drug.package_type) === String(drug2.package_type) &&
  String(drug.quantity) === String(drug2.quantity) &&
  String(drug.frequency) === String(drug2.frequency);

export const hasDuplicatePrescriptions = (
  prescriptions: PrescriptionDrug[]
): boolean => {
  return prescriptions.some((prescription, pIndex, pArray) =>
    pArray
      .filter((_p, i) => i !== pIndex)
      .some(otherDrug => drugsAreDuplicates(otherDrug, prescription))
  );
};
