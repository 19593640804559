import React from "react";

/**
 * Lazy loaded React Query dev tools to add to production for debugging.
 * @see https://tanstack.com/query/v4/docs/react/devtools#devtools-in-production
 */
export const ReactQueryProductionDevtools = React.lazy(() =>
  import("@tanstack/react-query-devtools/build/lib/index.prod.js").then(d => ({
    default: d.ReactQueryDevtools,
  }))
);
