import React, { useState, FunctionComponent } from "react";
import { Button } from "@cmsgov/ds-medicare-gov";
import { ExtraSSHelp, HowDoYouGetHelp } from ".";
import {
  ActionType,
  LowIncomeSubsidyOption,
  LowIncomeSubsidyAmount,
  LowIncomeSubsidyStatus,
  FullPlanId,
} from "../../@types";
import { RouteComponentProps, withRouter } from "react-router";
import {
  getNewLISCodeFromUserOptions,
  initiateLogin,
  shouldHideExtraSSHelp,
  useAppContext,
  useCurrentPlanYear,
  useTypedTranslate,
  useYearPart,
} from "../../helpers";
import { fetchCurrentPlan } from "../../helpers/planHelpers";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../../app/contexts/Analytics/types";
import { LISQuestionsUpdate } from "./LISQuestionsUpdate";
import { useFlags } from "launchdarkly-react-client-sdk";

export const lisContinueButtonTestId = "lis-questions-lis-button";

interface LISQuestionsProps extends RouteComponentProps {
  nextPage: () => void;
}

const LISQuestions: FunctionComponent<LISQuestionsProps> = ({ nextPage }) => {
  const t = useTypedTranslate();
  const { tmpFeEnableHelpWithCostsImprovements } = useFlags();
  const [helpType, setHelpType] = useState<undefined | LowIncomeSubsidyOption>(
    undefined
  );
  const [amountOfSSHelp, setAmountOfSSHelp] =
    useState<LowIncomeSubsidyAmount | null>(null);
  const { state, dispatch } = useAppContext();
  const mctCurrentPlanYear = useCurrentPlanYear();
  const { isOpenEnrollmentNextYearOnly } = useYearPart();

  const hideExtraSSHelp = shouldHideExtraSSHelp(
    mctCurrentPlanYear,
    isOpenEnrollmentNextYearOnly
  );

  // Determines if the user has selected a Help Type that does not require further input
  const undefinitiveLowIncomeSubsidyOption = [LowIncomeSubsidyOption.Unsure];
  if (!hideExtraSSHelp) {
    undefinitiveLowIncomeSubsidyOption.push(LowIncomeSubsidyOption.SS);
  }
  const helpTypeIsDefinitive =
    helpType && !undefinitiveLowIncomeSubsidyOption.includes(helpType);

  // Calculate if we have what we need to allow the user to continue
  const canContinue =
    (helpType && helpTypeIsDefinitive) || amountOfSSHelp !== null;

  const handleContinuing = (
    helpType: LowIncomeSubsidyOption | undefined
  ): void => {
    const selectedLIS = getNewLISCodeFromUserOptions(
      hideExtraSSHelp,
      helpType,
      amountOfSSHelp
    );

    dispatch({
      type: ActionType.UPDATE_LIS,
      payload: selectedLIS,
    });

    if (selectedLIS !== state.lis) {
      fetchCurrentPlan(
        state.beneficiary,
        state.pharmacies,
        selectedLIS || LowIncomeSubsidyStatus.LIS_NO_HELP,
        dispatch,
        [state.nextYearCoverage as FullPlanId],
        state.futureLis
      );
    }

    nextPage();
  };

  const dispatchLoginAnalyticEvents = () => {
    dispatch({
      type: AnalyticsActionType.SEND_TEALIUM_EVENT,
      settings: {
        event_action: "Find Plans - Choose Plans",
        event_label: "Login",
      },
    });
    dispatch({
      type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
      settings: {
        button: {
          buttonStyle: AnalyticsButtonStyle.DEFAULT,
          buttonType: AnalyticsButtonType.BUTTON,
          text: t("logged_out_footer.login"),
        },
      },
    });
  };

  const handleLoginClick = (): void => {
    dispatchLoginAnalyticEvents();
    initiateLogin(state.language, "", undefined);
  };

  return tmpFeEnableHelpWithCostsImprovements ? (
    <LISQuestionsUpdate
      onClickContinue={(selectedHelpType: LowIncomeSubsidyOption) => {
        handleContinuing(selectedHelpType);
      }}
      onClickLogin={dispatchLoginAnalyticEvents}
    />
  ) : (
    <>
      <HowDoYouGetHelp
        answeredTypeOfHelp={(value: LowIncomeSubsidyOption): void =>
          setHelpType(value)
        }
      />

      {helpType &&
        helpType === LowIncomeSubsidyOption.SS &&
        !hideExtraSSHelp && (
          <ExtraSSHelp
            updateAmountOfSSHelp={(
              value: LowIncomeSubsidyAmount | null
            ): void => setAmountOfSSHelp(value)}
          />
        )}

      {helpType && helpType === LowIncomeSubsidyOption.Unsure && (
        <section data-testid="continue" className="ContinueSection">
          <Button
            onClick={handleLoginClick}
            className="ds-u-margin-bottom--4 ds-u-lg-margin-bottom--0 ds-u-md-margin-right--2 mct-u-width--full mct-u-md-width--auto"
            variation="solid"
          >
            {t("logged_out_footer.login")}
          </Button>
          <Button
            className="mct-u-width--full mct-u-md-width--auto"
            onClick={(): void => {
              dispatch({
                type: AnalyticsActionType.SEND_TEALIUM_EVENT,
                settings: {
                  event_action: "Find Plans - Choose Plans",
                  event_label: "Continue without Logging in",
                },
              });
              dispatch({
                type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
                settings: {
                  button: {
                    buttonStyle: AnalyticsButtonStyle.DEFAULT,
                    buttonType: AnalyticsButtonType.BUTTON,
                    text: t("landing_page.continue_without_login"),
                  },
                },
              });
              handleContinuing(helpType);
            }}
          >
            {t("landing_page.continue_without_login")}
          </Button>
        </section>
      )}

      {canContinue && (
        <section data-testid="continue" className="ContinueSection">
          <Button
            variation="solid"
            data-testid={lisContinueButtonTestId}
            className="mct-u-width--full mct-u-md-width--auto"
            onClick={(): void => {
              dispatch({
                type: AnalyticsActionType.SEND_TEALIUM_EVENT,
                settings: {
                  event_action: "Find Plans",
                  event_label: "Continue",
                },
              });
              dispatch({
                type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
                settings: {
                  button: {
                    buttonStyle: AnalyticsButtonStyle.PRIMARY,
                    buttonType: AnalyticsButtonType.BUTTON,
                    text: t("landing_page.continue"),
                  },
                },
              });
              handleContinuing(helpType);
            }}
          >
            {t("landing_page.continue")}
          </Button>
        </section>
      )}
    </>
  );
};

export default withRouter(LISQuestions);
