import React from "react";
import { FormattedMessage } from "react-intl";

export const M3PModalPlanHeading = ({
  withPlan = true,
}: {
  withPlan?: boolean;
}) => (
  <FormattedMessage
    id={`m3p.modal.table.heading.plan.${withPlan ? "with" : "without"}`}
    values={{
      normal: (chunks: string[]) => (
        <span className="ds-u-font-weight--normal">{chunks}</span>
      ),
    }}
  />
);
