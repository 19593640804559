import React, { FunctionComponent, useReducer, useEffect } from "react";
import { ActionType, AppState } from "../../@types";

// Do not attempt to import from re-exports (../../helpers) at the moment
// This breaks tests
import { storageKey } from "../../helpers/storeHelpers";
import { useHistory } from "react-router";
import { useFlags } from "launchdarkly-react-client-sdk";
import isEqual from "lodash.isequal";
import { reducer } from "./reducer";
import { AppContext, initialAppState } from "./appStoreHelpers";

export const AppStoreProvider: FunctionComponent<{
  initialState?: AppState;
}> = props => {
  // * Hooks
  const history = useHistory();
  const flags = useFlags();

  // * Reducer
  const [state, dispatch] = useReducer(
    reducer,
    props.initialState || { ...initialAppState, tealiumLoaded: false }
  );

  // * Effects

  useEffect(() => {
    // Every time the state changes, store it in sessionStorage
    window.sessionStorage.setItem(storageKey, JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    // Need deep equality check to handle memory leak caused by jest-launchdarkly-mock recreating flags object every test run case run
    if (!isEqual(state.cachedFlags, flags)) {
      dispatch({ type: ActionType.UPDATE_LD_FLAGS, payload: flags });
    }
  }, [flags]);

  // For the purposes of testing global session management, we need to be able to
  // set app state within integration tests

  useEffect(() => {
    if (window.Cypress) {
      window.store = {
        state,
        dispatch,
      };
      window.router = { history };
    }
  }, [history, state]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AppContext.Provider>
  );
};
