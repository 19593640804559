import { useEffect, useState } from "react";

/**
 * Returns the Cypress.env object within any React component
 * This would include default configuration options as well as anything
 * passed through CLI using a `CYPRESS_`-prefixed value
 */
export const useCypressEnv = () => {
  const [env, setEnv] = useState<Record<string, unknown>>({});
  useEffect(() => {
    const cypressEnvFn =
      typeof window.Cypress === "object" &&
      null !== window.Cypress &&
      "env" in window.Cypress &&
      typeof window.Cypress.env === "function"
        ? (window.Cypress.env as () => Record<string, unknown>)
        : () => ({});
    const cypressEnv = cypressEnvFn();
    setEnv(cypressEnv);
  }, []);
  return env;
};
