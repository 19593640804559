import React, { FunctionComponent, useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { AppContext } from "../app/store";
import { hasGlobalSession } from "../helpers";

const PrivateRoute: FunctionComponent<RouteProps> = ({ children, ...rest }) => {
  const { state } = useContext(AppContext);

  if (hasGlobalSession(state)) {
    return <Route {...rest}>{children}</Route>;
  }

  // Not authenticated, no bene, go to landing
  return <Redirect to="/" />;
};

export default PrivateRoute;
