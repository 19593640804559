import React from "react";
import AppContent from "./AppContent";
import { AppStoreProvider } from "./store";
import { TealiumHandler } from "./TealiumHandler";
import { AlertMessageProvider } from "./contexts";

export const App: React.FunctionComponent = () => {
  return (
    <AppStoreProvider>
      <TealiumHandler>
        <AlertMessageProvider>
          <AppContent />
        </AlertMessageProvider>
      </TealiumHandler>
    </AppStoreProvider>
  );
};

export default App;
