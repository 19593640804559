import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { CollapsibleBox } from ".";
import { PreviewContext, useTranslate } from "../helpers";
import { Ga4Event } from "../app/contexts/Analytics/types";

const LimitsApplyAccordion: React.FunctionComponent<{
  descriptors: {
    authorization_required: boolean;
    plan_limits: boolean;
    referral_required: boolean;
    cost_share?: string;
  };
  ga4SectionName?: string;
  tealiumSectionName?: string;
}> = ({
  descriptors: { authorization_required, plan_limits, referral_required },
  ga4SectionName = "",
  tealiumSectionName,
}) => {
  const previewMode = useContext(PreviewContext);
  const t = useTranslate();
  if (!authorization_required && !plan_limits && !referral_required)
    return null;

  return (
    <div data-testid="limits-accordion">
      <CollapsibleBox
        labelId={"plan_terms.limits_apply"}
        titleClassname="ds-u-margin--0"
        tealiumProps={{
          event_action: "Plan Details Help Caret",
          event_label: tealiumSectionName || "unspecified",
        }}
        ga4Props={{
          event_name: Ga4Event.PLAN_DETAILS_HELP_CARET_CLICKED,
          sub_section: ga4SectionName,
          header: t("plan_terms.limits_apply"),
        }}
        open={previewMode}
      >
        {authorization_required && (
          <div className="ds-u-margin-y--2">
            <FormattedMessage
              id="plan_terms.limits.advanced_plan_approval_required"
              values={{
                strong: (chunks: string[]) => <strong>{chunks}</strong>,
              }}
            />
          </div>
        )}

        {referral_required && (
          <div className="ds-u-margin-y--2">
            <FormattedMessage
              id="plan_terms.limits.physician_referral_required"
              values={{
                strong: (chunks: string[]) => <strong>{chunks}</strong>,
              }}
            />
          </div>
        )}

        {plan_limits && (
          <div className="ds-u-margin-y--2">
            <FormattedMessage
              id="plan_terms.limits.plan_limits"
              values={{
                strong: (chunks: string[]) => <strong>{chunks}</strong>,
              }}
            />
          </div>
        )}
      </CollapsibleBox>
    </div>
  );
};

export default LimitsApplyAccordion;
