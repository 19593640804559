import * as React from "react";
import { useContext } from "react";
import { IntlShape, WrappedComponentProps, injectIntl } from "react-intl";
import { UseTranslateType, UseTranslateTypeStrict, Window } from "../@types";
import EnglishTranslations from "../translations/en-US.json";
declare let window: Window;

export const IntlContext = React.createContext<IntlShape>({} as IntlShape);

const IntlProvider: React.FunctionComponent<WrappedComponentProps> = ({
  children,
  intl,
}) => {
  return <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>;
};

/** turn the old context into the new context */
export const InjectIntlContext = injectIntl(IntlProvider);

/** Allows for intellisense autocomplete of PA key names */
export type TranslationKey = keyof typeof EnglishTranslations;

function isTranslationKey(string: unknown): string is TranslationKey {
  return typeof string === "string" && string in EnglishTranslations;
}

/** Makes PhraseApp compatible ID strings, for use with the in context editor  */
const phraseappEscapeId = (id: string): string => `{{__phrase_${id}__}}`;

/** the format message hook  */
export const useFormatMessage = () => {
  const intl = useContext(IntlContext);

  const fm = React.useMemo(() => {
    if ((window as Window).PHRASEAPP_ENABLED) {
      return ({ id }: { id: TranslationKey }): string => phraseappEscapeId(id);
    } else {
      return intl.formatMessage;
    }
  }, [intl.formatMessage]);
  return fm;
};

export const useIntl = (): IntlShape => {
  return useContext(IntlContext);
};

/**
 * @deprecated
 * Prefer `useTypedTranslate` and `UseTranslateTypeStrict` until uses of the
 * original hook and type are all replaced.
 * Then `useTypedTranslate` can be renamed `useTranslate` and
 * `UseTranslateTypeStrict` can be renamed `UseTranslateType`
 */
export const useTranslate = (): UseTranslateType => {
  const fm = useFormatMessage();
  const t = React.useCallback(
    (id: TranslationKey | string): string => {
      if (!isTranslationKey(id)) {
        return id;
      }

      if ((window as Window).PHRASEAPP_ENABLED) {
        return phraseappEscapeId(id);
      } else {
        return fm({ id });
      }
    },
    [fm]
  );
  return t;
};

/**
 * Does not check for the existence of a TranslationKey. The returned function
 * won't accept any string other than an existing key from the current set of translations
 * @see `useTranslate`
 */
export const useTypedTranslate = (): UseTranslateTypeStrict => {
  const fm = useFormatMessage();
  const t = React.useCallback(
    (id: TranslationKey): string => {
      if ((window as Window).PHRASEAPP_ENABLED) {
        return phraseappEscapeId(id);
      } else {
        return fm({ id });
      }
    },
    [fm]
  );
  return t;
};
