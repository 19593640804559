import { MedigapPlanPolicy, MedigapPlanType } from "../../@types";
import { getMedigapPlanPolicies } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { QueryKey, StaleTime } from "./types";
import { useAppContext } from "../context-hooks";
import { useMemo } from "react";
import { useRouteParams } from "../routing-hooks";

/**
 * a hook to access a medigap policies, based on geographic and demographic info
 */
export const useMedigapPolicies = () => {
  // * Context
  const {
    state: { county, zipcode = "", demographicInfo },
  } = useAppContext();
  const stateAbbrev = county?.state || "";

  // * Routing
  const { medigapPlanType } = useRouteParams();

  // * Queries
  const medigapPoliciesQuery = useQuery({
    queryKey: [
      QueryKey.MedigapPolicies,
      stateAbbrev,
      zipcode,
      medigapPlanType,
      demographicInfo,
    ],
    queryFn: () => {
      return getMedigapPlanPolicies(
        stateAbbrev,
        zipcode,
        medigapPlanType as MedigapPlanType,
        demographicInfo
      );
    },
    enabled: !!(zipcode && stateAbbrev && medigapPlanType),
    staleTime: StaleTime.Infinite,
  });

  // * Constants
  const medigapPolicies: MedigapPlanPolicy[] = useMemo(
    () => medigapPoliciesQuery.data || [],
    [medigapPoliciesQuery.data]
  );

  const error = medigapPoliciesQuery.isError;
  const loading = medigapPoliciesQuery.isLoading;

  return {
    /** an object containing medigap policies (from medigap/policies endpoint) */
    medigapPolicies,
    /** medigap policies query error */
    error,
    /** medigap policies query loading */
    loading,
  };
};
