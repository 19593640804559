import React, { useContext, useEffect, useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { AppContext } from "./store";
import { ActionType } from "../@types";
import {
  getTealiumAsyncScriptUrl,
  getTealiumEnvironment,
  getTealiumSyncScriptUrl,
} from "../helpers/tealiumHandlerHelpers";
import { getEnvironment } from "../helpers";

export const TealiumHandler: React.FunctionComponent = ({ children }) => {
  const {
    state: { tealiumLoaded },
    dispatch,
  } = useContext(AppContext);
  const [insertUtag, setInsertUtag] = useState(false);
  const { feEnableTealium, tmpFeEnableFirstPartyTealiumSource } =
    useFlags() as {
      feEnableTealium: boolean;
      tmpFeEnableFirstPartyTealiumSource: boolean;
    };
  const [script1Inserted, setScript1Inserted] = useState(false);
  const [script2Inserted, setScript2Inserted] = useState(false);
  const [script3Inserted, setScript3Inserted] = useState(false);

  useEffect(() => {
    (async () => {
      if (tealiumLoaded) {
        return;
      }
      if (feEnableTealium) {
        if (!window["jQuery"]) {
          const $ = await import("jquery/dist/jquery.slim.min");
          window["jQuery"] = $.default;
          window["$"] = $.default;
        }

        if (!script1Inserted && !script2Inserted && !script3Inserted) {
          setInsertUtag(true);
        } else {
          // `state.tealiumLoaded` can get unset when redirecting from a callback route
          dispatch({
            type: ActionType.SET_TEALIUM_LOADED,
            payload: true,
          });
        }
      }
    })();
  }, [feEnableTealium, tealiumLoaded]);

  useEffect(() => {
    if (insertUtag) {
      const env = getEnvironment();
      const tealiumEnv = getTealiumEnvironment(env);

      if (!script1Inserted) {
        const script1 = document.createElement("script");
        script1.innerHTML =
          "var utag_data; window.utag_cfg_ovrd = { noview: true };";
        script1.type = "text/javascript";
        document.head.appendChild(script1);
        setScript1Inserted(true);
      }

      if (!script2Inserted) {
        const script2 = document.createElement("script");
        script2.type = "text/javascript";
        script2.src = getTealiumSyncScriptUrl(
          tealiumEnv,
          tmpFeEnableFirstPartyTealiumSource
        );
        script2.defer = true;
        document.head.appendChild(script2);
        setScript2Inserted(true);
      }

      if (!script3Inserted) {
        const script3 = document.createElement("script");
        script3.src = getTealiumAsyncScriptUrl(
          tealiumEnv,
          tmpFeEnableFirstPartyTealiumSource
        );
        script3.type = "text/javascript";
        script3.async = true;
        script3.onload = (): void => {
          dispatch({
            type: ActionType.SET_TEALIUM_LOADED,
            payload: true,
          });
        };
        document.body.appendChild(script3);
      }
      setScript3Inserted(true);
    }
  }, [insertUtag]);

  return (
    <>
      {insertUtag && (
        <span
          id="app-utag1"
          data-testid="app-utag1"
          className="ds-u-display--none"
        >
          Tealium Enabled
        </span>
      )}
      {children}
    </>
  );
};
