import {
  LowIncomeSubsidyAmount,
  LowIncomeSubsidyOption,
  LowIncomeSubsidyStatus,
} from "../@types";
import { TranslationKey } from "./intlHooks";

type LISStatusLabelsType = {
  [key: string]: {
    [key in LowIncomeSubsidyStatus]: TranslationKey;
  };
};

/**
 * lowIncomeSubsidyStatusLabels define phrase keys for a given year and LIS level.
 */
const lowIncomeSubsidyStatusLabels: LISStatusLabelsType = {
  "2021": {
    [LowIncomeSubsidyStatus.LIS_LEVEL_1A]: "csr_landing.lis.2021.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_1A_DN]: "csr_landing.lis.2021.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_1B]: "na",
    [LowIncomeSubsidyStatus.LIS_LEVEL_1C]: "na",
    [LowIncomeSubsidyStatus.LIS_LEVEL_2]: "csr_landing.lis.2021.level2",
    [LowIncomeSubsidyStatus.LIS_LEVEL_3]: "csr_landing.lis.level3",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_100]: "csr_landing.lis.2021.level4_100",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_75]: "csr_landing.lis.2021.level4_75",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_50]: "csr_landing.lis.2021.level4_50",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_25]: "csr_landing.lis.2021.level4_25",
    [LowIncomeSubsidyStatus.LIS_NO_HELP]: "csr_landing.lis.no_help",
  },
  "2022": {
    [LowIncomeSubsidyStatus.LIS_LEVEL_1A]: "csr_landing.lis.2022.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_1A_DN]: "csr_landing.lis.2022.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_1B]: "na",
    [LowIncomeSubsidyStatus.LIS_LEVEL_1C]: "na",
    [LowIncomeSubsidyStatus.LIS_LEVEL_2]: "csr_landing.lis.2022.level2",
    [LowIncomeSubsidyStatus.LIS_LEVEL_3]: "csr_landing.lis.level3",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_100]: "csr_landing.lis.2022.level4_100",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_75]: "csr_landing.lis.2022.level4_75",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_50]: "csr_landing.lis.2022.level4_50",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_25]: "csr_landing.lis.2022.level4_25",
    [LowIncomeSubsidyStatus.LIS_NO_HELP]: "csr_landing.lis.no_help",
  },
  "2023": {
    [LowIncomeSubsidyStatus.LIS_LEVEL_1A]: "csr_landing.lis.2023.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_1A_DN]: "csr_landing.lis.2023.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_1B]: "na",
    [LowIncomeSubsidyStatus.LIS_LEVEL_1C]: "csr_landing.lis.2023.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_2]: "csr_landing.lis.2023.level2",
    [LowIncomeSubsidyStatus.LIS_LEVEL_3]: "csr_landing.lis.level3",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_100]: "csr_landing.lis.2023.level4_100",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_75]: "csr_landing.lis.2023.level4_75",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_50]: "csr_landing.lis.2023.level4_50",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_25]: "csr_landing.lis.2023.level4_25",
    [LowIncomeSubsidyStatus.LIS_NO_HELP]: "csr_landing.lis.no_help",
  },
  "2024": {
    [LowIncomeSubsidyStatus.LIS_LEVEL_1A]: "csr_landing.lis.2024.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_1A_DN]: "csr_landing.lis.2024.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_1B]: "na",
    [LowIncomeSubsidyStatus.LIS_LEVEL_1C]: "csr_landing.lis.2024.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_2]: "csr_landing.lis.2024.level2",
    [LowIncomeSubsidyStatus.LIS_LEVEL_3]: "csr_landing.lis.level3",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_100]: "csr_landing.lis.2024.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_75]: "csr_landing.lis.2024.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_50]: "csr_landing.lis.2024.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_25]: "csr_landing.lis.2024.level1A",
    [LowIncomeSubsidyStatus.LIS_NO_HELP]: "csr_landing.lis.no_help",
  },
  "2025": {
    [LowIncomeSubsidyStatus.LIS_LEVEL_1A]: "csr_landing.lis.2025.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_1A_DN]: "csr_landing.lis.2025.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_1B]: "na",
    [LowIncomeSubsidyStatus.LIS_LEVEL_1C]: "csr_landing.lis.2025.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_2]: "csr_landing.lis.2025.level2",
    [LowIncomeSubsidyStatus.LIS_LEVEL_3]: "csr_landing.lis.level3",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_100]: "csr_landing.lis.2025.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_75]: "csr_landing.lis.2025.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_50]: "csr_landing.lis.2025.level1A",
    [LowIncomeSubsidyStatus.LIS_LEVEL_4_25]: "csr_landing.lis.2025.level1A",
    [LowIncomeSubsidyStatus.LIS_NO_HELP]: "csr_landing.lis.no_help",
  },
} as const;

/**
 * Returns the phrase key for the given year and LIS level.
 * @param year the year
 * @param level the LIS level
 * @returns the phrase key for the given year and LIS level.
 */
export const getLowIncomeSubsidyStatusLabel = (
  year: string,
  level: LowIncomeSubsidyStatus
): string =>
  isLowIncomeSubsidyStatusLabelAvailable(year, level)
    ? lowIncomeSubsidyStatusLabels[year][level]
    : `missing ${year} phrase key for ${level}`;

export const isLowIncomeSubsidyStatusLabelAvailable = (
  year: string,
  level: LowIncomeSubsidyStatus
): boolean => {
  if (lowIncomeSubsidyStatusLabels[year]) {
    return lowIncomeSubsidyStatusLabels[year][level] ? true : false;
  } else {
    return false;
  }
};

export const getNewLISCodeFromUserOptions = (
  hideExtraSSHelp: boolean,
  helpType: LowIncomeSubsidyOption | undefined,
  amountOfSSHelp: LowIncomeSubsidyAmount | null
): LowIncomeSubsidyStatus => {
  if (helpType === LowIncomeSubsidyOption.Medicaid) {
    return LowIncomeSubsidyStatus.LIS_LEVEL_1A;
  }
  if (helpType === LowIncomeSubsidyOption.SSI) {
    return LowIncomeSubsidyStatus.LIS_LEVEL_1A_DN;
  }
  if (helpType === LowIncomeSubsidyOption.MSP) {
    return LowIncomeSubsidyStatus.LIS_LEVEL_1B;
  }
  if (helpType === LowIncomeSubsidyOption.Unsure) {
    return LowIncomeSubsidyStatus.LIS_NO_HELP;
  }
  if (helpType === LowIncomeSubsidyOption.SS) {
    if (hideExtraSSHelp) {
      return LowIncomeSubsidyStatus.LIS_LEVEL_1C;
    }
    if (amountOfSSHelp === LowIncomeSubsidyAmount.HelpNA) {
      return LowIncomeSubsidyStatus.LIS_LEVEL_1C;
    }
    if (amountOfSSHelp === LowIncomeSubsidyAmount.Help0) {
      return LowIncomeSubsidyStatus.LIS_LEVEL_4_100;
    }
    if (amountOfSSHelp === LowIncomeSubsidyAmount.Help25) {
      return LowIncomeSubsidyStatus.LIS_LEVEL_4_75;
    }
    if (amountOfSSHelp === LowIncomeSubsidyAmount.Help50) {
      return LowIncomeSubsidyStatus.LIS_LEVEL_4_50;
    }
    if (amountOfSSHelp === LowIncomeSubsidyAmount.Help75) {
      return LowIncomeSubsidyStatus.LIS_LEVEL_4_25;
    }
    if (amountOfSSHelp === LowIncomeSubsidyAmount.HelpUnsure) {
      return LowIncomeSubsidyStatus.LIS_LEVEL_4_100;
    }
  }
  return LowIncomeSubsidyStatus.LIS_NO_HELP;
};

export const getUserOptionFromLISCode = (
  lis?: LowIncomeSubsidyStatus
): LowIncomeSubsidyOption | undefined => {
  switch (lis) {
    case LowIncomeSubsidyStatus.LIS_LEVEL_1A:
      return LowIncomeSubsidyOption.Medicaid;
    case LowIncomeSubsidyStatus.LIS_LEVEL_1A_DN:
      return LowIncomeSubsidyOption.SSI;
    case LowIncomeSubsidyStatus.LIS_LEVEL_1B:
      return LowIncomeSubsidyOption.MSP;
    case LowIncomeSubsidyStatus.LIS_LEVEL_1C:
      return LowIncomeSubsidyOption.SS;
    case LowIncomeSubsidyStatus.LIS_NO_HELP:
      return LowIncomeSubsidyOption.None;
  }
  return undefined;
};

export const shouldHideExtraSSHelp = (
  mctCurrentPlanYear: number,
  isOpenEnrollmentNextYearOnly: boolean | undefined
) =>
  (mctCurrentPlanYear === 2023 && !!isOpenEnrollmentNextYearOnly) ||
  mctCurrentPlanYear > 2023;

/**
 * Returns true if the LIS level indicates Dual Special Needs Plan -
 * Has both Medicare and Medicaid.
 */
export const isDSNP = (lis: LowIncomeSubsidyStatus | undefined): boolean =>
  !!lis &&
  [
    LowIncomeSubsidyStatus.LIS_LEVEL_1A,
    LowIncomeSubsidyStatus.LIS_LEVEL_2,
    LowIncomeSubsidyStatus.LIS_LEVEL_3,
  ].includes(lis);
