import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
} from "react";
import { FormattedMessage } from "react-intl";
import { Button, Dialog, LockIcon } from "@cmsgov/ds-medicare-gov";
import { makePlanLongId, useIsCsrSession, useTranslate } from "../helpers";
import {
  ActionType,
  Plan,
  PlanType,
  SearchResultPlan,
  PlanEnrollmentStatus,
  PharmacyType,
} from "../@types";
import { AppStore } from "../app/store";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isCurrentPlan } from "../helpers/beneficiaryInfoHelpers";
import { RouteComponentProps, withRouter } from "react-router";
import routes from "../app/routes";
import { getPlanName } from "../helpers/planHelpers";
import { useCurrentPlanYear, useYearPart } from "../helpers/yearFlagHelpers";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
  Ga4Event,
  Ga4EventDimensions,
} from "../app/contexts/Analytics/types";
import { usePendingEnrollments } from "../helpers/query-hooks/usePendingEnrollments";

export interface EnrollOptions extends RouteComponentProps {
  plan: Plan | SearchResultPlan;
  aria_string?: string;
  onClick?: () => void;
  tealiumEventAction?: string;
  buttonClassName?: string;
  dialogClassName?: string;
}

type MissingGa4EventDimensions = Pick<
  Partial<Ga4EventDimensions>,
  "mct_pharmacy_type" | "mct_insurance_provider" | "mct_insurance_type"
>;

const EnrollButtonForm: FunctionComponent<EnrollOptions> = ({
  plan,
  plan: {
    name,
    plan_id,
    contract_id,
    contract_year,
    segment_id,
    low_performing,
    enrollment_status,
  },
  onClick,
  history,
  aria_string,
  tealiumEventAction,
  buttonClassName = "",
  dialogClassName = "",
}) => {
  // * Hooks
  const t = useTranslate();

  // * Flags
  const { feDisableHpmsEnrollment } = useFlags();
  const currentPlanYear = useCurrentPlanYear();
  const { isPublicPreview, isOpenEnrollmentNextYearOnly } = useYearPart();

  // * Context
  const { state, dispatch } = useContext(AppStore.AppContext);

  // * Queries
  const {
    oecStatuses: { currentYear, nextYear },
  } = usePendingEnrollments();

  // * State
  const [showTransitionModal, setTransitionModalVisibility] = useState(false);

  // * Constants
  const hideEnrollForCurrentYearPlans =
    isOpenEnrollmentNextYearOnly &&
    plan.contract_year === currentPlanYear.toString();
  const tealiumPlanType = plan.plan_type === PlanType.PDP ? "PDP" : "MA/MAPD";
  const tealiumEventLabel = `Enroll - ${name} - ${tealiumPlanType}`;

  const pendingEnrollmentPlansLongIds: string[] = [];
  if (currentYear) {
    pendingEnrollmentPlansLongIds.push(makePlanLongId(currentYear));
  }
  if (nextYear) {
    pendingEnrollmentPlansLongIds.push(makePlanLongId(nextYear));
  }
  const currentPlanLongId = makePlanLongId(plan);
  const planIsPendingEnrollment =
    pendingEnrollmentPlansLongIds.includes(currentPlanLongId);

  // * Effects

  useEffect(() => {
    if (showTransitionModal) {
      const dialogWrapper: HTMLElement | null =
        document.querySelector(".ds-c-dialog-wrap");

      if (dialogWrapper) {
        dialogWrapper.scrollTop = 0;
      }
    }
  }, [showTransitionModal]);

  if (low_performing) {
    return (
      <p className="ds-u-text-align--center">
        {t("plan_card.contact_plan_to_enroll")}
      </p>
    );
  }

  const openEnrollModal = (): void => {
    setTransitionModalVisibility(true);
  };

  const isCurrPlan = isCurrentPlan(state.currentCoverage, {
    plan_id,
    contract_id,
    contract_year,
    segment_id,
  });

  const isCurrPlanForNextYear = isCurrentPlan(state.nextYearCoverage, {
    plan_id,
    contract_id,
    contract_year,
    segment_id,
  });

  const enrollAnalytics = (): void => {
    const optionalSettings: MissingGa4EventDimensions = {};
    if (!state.selectedPlan) {
      optionalSettings.mct_insurance_provider = plan.name;
      optionalSettings.mct_insurance_type = plan.plan_type;
    }
    if (!state.pharmacyType) {
      optionalSettings.mct_pharmacy_type = PharmacyType.NONE;
    }
    dispatch({
      type: AnalyticsActionType.SEND_GA4_EVENT,
      settings: {
        event_name: Ga4Event.PLAN_ENROLL_CLICKED,
        ...optionalSettings,
      },
    });
    dispatch({
      type: AnalyticsActionType.SEND_TEALIUM_EVENT,
      settings: {
        event_action: tealiumEventAction || "Button",
        event_label: tealiumEventLabel,
      },
    });
  };

  const EnrollButton = (): JSX.Element => {
    const isCsr = useIsCsrSession();
    if (isCurrPlan && !isCsr) {
      return (
        <p className="mct-c-enroll-button-form__text ds-u-text-align--center">
          {t("plan_card.your_current_plan")}
        </p>
      );
    }

    if (isCurrPlanForNextYear && !isCsr) {
      return (
        <p className="mct-c-enroll-button-form__text ds-u-text-align--center">
          {t("plan_card.your_next_plan")}
        </p>
      );
    }

    const planFrozen = plan.redactions.some(
      r => r.type === "frozen" && r.issue === "frzn_enrlmnt"
    );
    const contactPlan = !plan.enrollment_opt_in_status || planFrozen;

    if (contactPlan) {
      return (
        <p className="mct-c-enroll-button-form__text">
          {t("plan_card.contact_plan_to_enroll")}
        </p>
      );
    } else if (enrollment_status === PlanEnrollmentStatus.NOT_NOW) {
      return (
        <p className="mct-c-enroll-button-form__text">
          {t("plan_enroll.not_accepting")}
        </p>
      );
    }

    if (feDisableHpmsEnrollment) {
      return (
        <p className="mct-c-enroll-button-form__text ds-u-font-style--italic">
          {t("plan_enroll.cannot_process_enrollments")}
        </p>
      );
    }

    return (
      <Button
        type="button"
        variation="solid"
        aria-label={aria_string}
        className={`e2e-plan-enroll mct-hide-print mct-c-enroll-button-form__button ${buttonClassName}`}
        onClick={(): void => {
          if (onClick) {
            onClick();
          }
          dispatch({
            type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
            settings: {
              button: {
                buttonStyle: AnalyticsButtonStyle.PRIMARY,
                buttonType: AnalyticsButtonType.BUTTON,
                text: t("app.terms.enroll"),
              },
            },
          });
          enrollAnalytics();
          openEnrollModal();
        }}
      >
        {t("app.terms.enroll")}
      </Button>
    );
  };

  const onCancel = (): void => {
    setTransitionModalVisibility(false);
    dispatch({
      type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
      settings: {
        button: {
          buttonStyle: AnalyticsButtonStyle.PRIMARY,
          buttonType: AnalyticsButtonType.BUTTON,
          text: t("plan_enroll.cancel"),
        },
      },
    });
    dispatch({
      type: AnalyticsActionType.SEND_TEALIUM_EVENT,
      settings: {
        event_action: tealiumEventAction || "Modal",
        event_label: "Go Back",
      },
    });
  };

  if (planIsPendingEnrollment) {
    return null;
  }

  return (
    <div
      className={`EnrollTransitionDialog PlanCard__mobile_order_2 ${dialogClassName}`}
    >
      {showTransitionModal && (
        <Dialog
          size="wide"
          className="EnrollTransitionDialog__dialog"
          headerClassName="EnrollTransitionDialog__header"
          onExit={onCancel}
        >
          <div className="EnrollTransitionDialog__content">
            <h1
              className="ds-text-heading--lg ds-u-margin-bottom--0"
              data-cy="enrollment-form-dlg-heading"
            >
              {+plan.contract_year === currentPlanYear + 1 ? (
                <FormattedMessage
                  id="plan_enroll.you_are_joining_with_year"
                  values={{ year: plan.contract_year }}
                />
              ) : (
                t("plan_enroll.you_are_joining")
              )}
            </h1>
            <div className="EnrollTransitionDialog__planDetails">
              <h2
                id="dialog-title"
                className="ds-text-heading--2xl ds-u-margin--0"
              >
                {getPlanName(plan, state.language)}
              </h2>
              <h3 className="ds-text-heading--xl ds-u-margin--0 ds-u-color--gray">
                {`${t(
                  "plan_terms.plan_id"
                )} ${contract_id}-${plan_id}-${segment_id}`}
              </h3>
            </div>
          </div>
          <div className="EnrollTransitionDialog__content">
            <b>{t("enroll_transition.checklist_heading")}:</b>
            <ul className="EnrollTransitionDialog__checklist">
              <li>{t("enroll_transition.checklist.1")}</li>
              <li>{t("enroll_transition.checklist.2")}</li>
              <li>{t("enroll_transition.checklist.3")}</li>
            </ul>
          </div>
          <div className="EnrollTransitionDialog__content ds-u-display--flex ds-u-justify--start ds-u-align-items--center">
            <LockIcon className="ds-u-margin-right--1" />
            <span className="ds-u-font-size--sm ds-u-color--gray">
              {t("enroll_transition.security_advisory.update")}
            </span>
          </div>

          <div className="EnrollTransitionDialog__actions">
            <Button
              className="EnrollTransitionDialog__cancelButton"
              key="cancel"
              onClick={onCancel}
              data-cy="enrollment-form-dlg-cancel-button"
            >
              {t(
                plan.contract_year === "2021"
                  ? "enroll_transition.2021.go_back"
                  : "plan_enroll.cancel"
              )}
            </Button>

            <Button
              variation="solid"
              className="e2e-submit-enrollment-form"
              key="primary"
              onClick={(): void => {
                dispatch({
                  type: ActionType.UPDATE_SELECTED_PLAN,
                  payload: plan,
                });
                history.push(routes.enroll);
                setTransitionModalVisibility(false);
                dispatch({
                  type: AnalyticsActionType.SEND_TEALIUM_EVENT,
                  settings: {
                    event_action: tealiumEventAction || "Modal",
                    event_label: `Join Plan - ${plan.name} - ${tealiumPlanType}`,
                  },
                });
                dispatch({
                  type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
                  settings: {
                    button: {
                      buttonStyle: AnalyticsButtonStyle.PRIMARY,
                      buttonType: AnalyticsButtonType.BUTTON,
                      text: t("plan_enroll.join_plan"),
                    },
                    linkUrl: routes.enroll,
                  },
                });
                dispatch({
                  type: AnalyticsActionType.SEND_GA4_EVENT,
                  settings: {
                    event_name: Ga4Event.ENROLLMENT_STARTED,
                  },
                });
              }}
            >
              {t("plan_enroll.join_plan")}
            </Button>
          </div>
        </Dialog>
      )}

      {isPublicPreview && (
        <>
          {plan.contract_year === (currentPlanYear + 1).toString() && (
            <p className="e2e-enroll-begins">
              {t("public_preview.enroll_begins")}
            </p>
          )}

          {plan.contract_year === currentPlanYear.toString() && (
            <EnrollButton />
          )}
        </>
      )}

      {!isPublicPreview && !hideEnrollForCurrentYearPlans && <EnrollButton />}

      {isCurrPlan && (
        <p>
          <FormattedMessage id="current_plan.current_coverage_disclaimer" />
        </p>
      )}
    </div>
  );
};

export default withRouter(EnrollButtonForm);
