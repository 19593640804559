import { useEffect, useContext, useCallback } from "react";
import { RouteComponentProps, useLocation } from "react-router";
import URI from "urijs";
import { AppContext } from "../app/store";
import {
  ActionType,
  UserLanguage,
  AppState,
  MctYearPartFlag,
  ManagedQueryParameters,
} from "../@types";
import { getShouldUpdateSearchYear, parseSearchParams } from ".";
import {
  getYearPartInfoFromYearPart,
  useCurrentPlanYear,
  useYearPart,
} from "./yearFlagHelpers";

/**
 * Manages setting and updating both year and language in the URL and application
 * state, keeping them in sync and ensuring that the year is valid for the currently
 * set year part (evaluated based on the current plan year)
 */
export const useQueryParams = ({
  history,
  location,
  match,
}: RouteComponentProps): {
  state: AppState;
  routeProps: RouteComponentProps;
} => {
  const {
    state,
    state: { language: languageInAppState, year: yearInAppState },
    dispatch,
  } = useContext(AppContext);
  const { yearPart } = useYearPart();
  const { search } = location;
  const { hash } = useLocation();
  const mctCurrentPlanYear = useCurrentPlanYear();

  /**
   * Updates the year in URL and/or app state
   * Also sets the language in the URL when updating, so it's not wiped out
   */
  const handleAppYear = useCallback(
    ({
      location,
      mctCurrentPlanYear,
      yearPart,
    }: {
      location: RouteComponentProps["location"];
      mctCurrentPlanYear: number;
      yearPart: MctYearPartFlag;
    }): void => {
      const uri = new URI(location.search);
      const { lang: languageInUrl, year: yearInUrl } = parseSearchParams(
        uri.search(true)
      ) as { [x: string]: string | undefined };
      const { shouldUpdateAppState, shouldUpdateUrl, yearToSet } =
        getShouldUpdateSearchYear({
          mctCurrentPlanYear,
          yearInAppState,
          yearInUrl,
          yearPart,
        });
      if (yearToSet) {
        const shouldPreserveLanguageInUrl =
          languageInUrl &&
          [UserLanguage.ENGLISH, UserLanguage.SPANISH].includes(
            languageInUrl as UserLanguage
          );
        if (shouldUpdateAppState) {
          dispatch({ type: ActionType.UPDATE_YEAR, payload: yearToSet });
        }
        if (shouldUpdateUrl) {
          uri.setQuery(ManagedQueryParameters.YEAR, yearToSet);
        }
        if (shouldPreserveLanguageInUrl) {
          uri.setQuery(ManagedQueryParameters.LANG, languageInUrl);
        }
        if (shouldUpdateUrl || shouldPreserveLanguageInUrl) {
          location.search = uri.search();
          location.hash = new URI().hash(hash || "").hash();
          history.replace(location);
        }
      }
    },
    [dispatch, history, yearInAppState, hash]
  );

  const handleAppLanguage = useCallback(
    (location: RouteComponentProps["location"]): void => {
      const uri = new URI(location.search);
      const { lang: languageInUrl } = parseSearchParams(uri.search(true)) as {
        [x: string]: string | undefined;
      };
      if (
        [UserLanguage.ENGLISH, UserLanguage.SPANISH].includes(
          languageInUrl as UserLanguage
        )
      ) {
        if (languageInAppState !== languageInUrl) {
          dispatch({
            type: ActionType.UPDATE_LANGUAGE,
            payload: languageInUrl,
          });
        }
      }

      if (!languageInUrl) {
        uri.setQuery(ManagedQueryParameters.LANG, languageInAppState);
        location.search = uri.search();
        location.hash = new URI().hash(hash || "").hash();
        history.replace(location);
      }
    },
    [dispatch, history, languageInAppState, hash]
  );

  /**
   * Watch changes to yearPart and query params and update values in app state and
   * the URL, accordingly
   */
  useEffect(() => {
    const ypFlagsAreDefined = Object.values(
      getYearPartInfoFromYearPart(yearPart)
    ).every(val => val !== undefined);
    if (!ypFlagsAreDefined) {
      return;
    }
    handleAppYear({
      location,
      mctCurrentPlanYear,
      yearPart,
    });
    // The way this hook is structured, we are modifying `location` directly.
    // For this reason, currently, `location` needs to be a dependency on the effects
    // or more accurately these memoized helpers, but adding it causes issues
    // (using up of the JS memory heap, tests never finishing).
    // We should look into using URI to take the existing pathname (new URI(pathname))
    // adding/updating the query (`newLocation.addSearch(...)`), and then passing that to
    // `history.replace` (but this will require ensuring that stringified query params are
    // combined and not replaced)
    // @see MCT-9130
  }, [handleAppYear, mctCurrentPlanYear, search, yearPart]);

  useEffect(() => {
    handleAppLanguage(location);
    // @see the note in the other effect helper
  }, [search, handleAppLanguage]);

  return { state: state, routeProps: { history, location, match } };
};
