import React, { FunctionComponent, useContext, useEffect } from "react";
import {
  AnalyticsActionType,
  Ga4EventDimension,
  Ga4ParentComponentType,
} from "../app/contexts/Analytics/types";
import { AppContext } from "../app/store";
import { getIsInternalAnchorLinkHref } from "../helpers";

/**
 * Data attribute to be added to the `<a>`` tag to override the default
 * analytic event
 */
export const CUSTOM_INTERNAL_LINK_CLICKED_EVENT =
  "data-custom-internal-link-clicked-event";

/**
 * AnalyticsListeners listens for click event and finds the closest
 * `<a>` tag to send INTERNAL_LINK_CLICKED analytic event.
 * The default event would include these properties:
 *  - link_text
 *  - link_url
 *
 * If more properties are needed, add the `data-custom-internal-link-clicked-event` data attribute to the `<a>` tag to override the default analytic event.
 *
 * Example:
 *
 * ```
 * <a data-custom-internal-link-clicked-event="true" href="#/m/plans">Change my search criteria.</a>
 * ```
 */
const AnalyticsListeners: FunctionComponent = ({ children }) => {
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    function sendInternalLinkClickEvent(event: Event) {
      const target = event.target as HTMLElement;
      const link = target.closest("a");

      if (link) {
        const isInternalAnchorLink = getIsInternalAnchorLinkHref(link.href);
        const attr = link.attributes.getNamedItem(
          CUSTOM_INTERNAL_LINK_CLICKED_EVENT
        );
        if (
          attr ||
          isInternalAnchorLink ||
          // Design system Tooltip components incorrectly use an anchor tag instead of button
          link.classList.contains("ds-c-tooltip__trigger-link")
        ) {
          // do not send default internal link clicked event
          return;
        }
        dispatch({
          type: AnalyticsActionType.SEND_INTERNAL_LINK_CLICKED_EVENT,
          settings: {
            linkText: link.innerText,
            linkUrl: link.getAttribute("href") || "N/A",
            [Ga4EventDimension.PARENT_COMPONENT_HEADING]:
              link.getAttribute(Ga4EventDimension.PARENT_COMPONENT_HEADING) ||
              "N/A",
            [Ga4EventDimension.PARENT_COMPONENT_TYPE]: (link.getAttribute(
              Ga4EventDimension.PARENT_COMPONENT_TYPE
            ) || Ga4ParentComponentType.NA) as Ga4ParentComponentType,
          },
        });
      }
    }

    window.addEventListener("click", sendInternalLinkClickEvent);

    return () =>
      window.removeEventListener("click", sendInternalLinkClickEvent);
  }, [dispatch]);

  return <>{children}</>;
};

export default AnalyticsListeners;
