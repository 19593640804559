import React, { ReactNode, Suspense, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactQueryProductionDevtools } from "./ReactQueryProductionDevtools";
import { DISABLE_REACT_QUERY_DEVTOOLS } from "../../config";
import { usePlaywrightEnv } from "../../helpers/testUtils/usePlaywrightEnv";

/** Wrapper for React Query */
export const QueryProvider: React.FunctionComponent = ({
  children,
}: {
  children?: ReactNode;
}) => {
  // * State
  const [showProductionDevtools, setShowProductionDevtools] = useState(false);
  const playwrightEnv = usePlaywrightEnv();

  // * Query Client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // TODO: We would like to set this to `true`, however it breaks Cypress tests.
        refetchOnWindowFocus: false, // default: true
      },
    },
  });

  // * Effects

  /**
   * calling `window.toggleDevtools()` will download the devtools bundle and show them.
   * @see https://tanstack.com/query/v4/docs/react/devtools#devtools-in-production
   **/
  useEffect(() => {
    // this is disabled to allow us to add a function to window.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).toggleDevtools = () =>
      setShowProductionDevtools(old => !old);
  }, []);

  const disableReactQueryDevtools =
    // disabled for Cypress
    window.Cypress ||
    // disabled for playwright
    playwrightEnv.DISABLE_REACT_QUERY_DEVTOOLS ||
    // env variable must be not be enabled
    DISABLE_REACT_QUERY_DEVTOOLS;

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {!disableReactQueryDevtools && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
      {showProductionDevtools && (
        <Suspense fallback={null}>
          <ReactQueryProductionDevtools />
        </Suspense>
      )}
    </QueryClientProvider>
  );
};
