import URI from "urijs";
import {
  AppState,
  Beneficiary,
  Envs,
  MBPLandingParams,
  MctToastType,
  UserLanguage,
} from "../@types";
import { CONSTANTS } from "./CONSTANTS";
import { config } from "../config";
import {
  userToQueryStringLangMap,
  getEnvironment,
  parseSearchParams,
} from "../helpers";
import { useContext, useMemo } from "react";
import { AppContext } from "../app/store";
import { RouteComponentProps, useLocation } from "react-router";

export const parseDegradedIntegrations = (headers: Headers): string[] => {
  const integrations = headers.get("failed-integrations") || "";

  return integrations
    .trim()
    .split(",")
    .map(x => x.trim())
    .filter(x => !!x);
};

export enum SlsxUrlAction {
  AUTHORIZE = "authorize",
  SIGNOUT = "signout",
}

export const formatSlsxUrl = (env: Envs, action: SlsxUrlAction): string => {
  const templateUrl = "https://{env}medicare.gov/sso/{action}";

  const urlReplacements = {
    [Envs.local]: "test.",
    [Envs.test]: "test.",
    [Envs.dev]: "dev1.",
    [Envs.imp]: "imp.",
    [Envs.prod]: "www.",
  };

  const urlReplacement = urlReplacements[env];

  return templateUrl
    .replace("{env}", urlReplacement)
    .replace("{action}", action);
};

/**
 * When in TEST environment and an FE_VERSION is set in app configuration (as part
 * of the build), returns a slash-prefixed preview path. Otherwise, returns an
 * empty string, so can be interpolated into any URL and won't affect anything
 * other than TEST preview URLs
 */
export const getPreviewPath = (env: Envs) => {
  const feVersion = Number(config.FE_VERSION) || NaN;
  if (isNaN(feVersion) || env !== Envs.test) {
    return "";
  }
  return `/preview/${feVersion}`;
};

export const getSlsxSignoutUrl = ({
  queriesToAppend,
}: {
  queriesToAppend?: Record<string, string>;
} = {}): string => {
  const env = getEnvironment();
  // Unless SLSx updates rules, redirect to localhost does not work, so redirect
  // from signout on localhost to test
  let domain = "";
  switch (env) {
    case Envs.local:
      domain = Envs.test;
      break;
    case Envs.prod:
      domain = "www";
      break;
    default:
      domain = env;
  }

  const baseUri = `${
    env === Envs.local
      ? "localhost"
      : `${domain}.medicare.gov/plan-compare${getPreviewPath(env)}`
  }`;

  let redirectUri = `https://${baseUri}${queriesToAppend ? "/#/" : ""}`;

  if (queriesToAppend) {
    Object.entries(queriesToAppend).forEach((kv, i) => {
      redirectUri += `${i === 0 ? "?" : "&"}${kv[0]}=${kv[1]}`;
    });
  }

  const signoutUrl = new URI(formatSlsxUrl(env, SlsxUrlAction.SIGNOUT))
    .addQuery("redirect_uri", redirectUri)
    .toString();

  return signoutUrl;
};

export const getLoginParams = (
  env: Envs
): {
  client_id: string;
  mbp_uri: string;
  redirect_uri: string;
} => {
  const clientIds = {
    [Envs.local]: "mct-test",
    [Envs.test]: "mct-test",
    [Envs.dev]: "mct-dev",
    [Envs.imp]: "mct-imp",
    [Envs.prod]: "mct",
  };

  const client_id = clientIds[env];

  const mbp_uri = formatSlsxUrl(env, SlsxUrlAction.AUTHORIZE);

  const slsCallbackPath = `/${CONSTANTS.slsCallbackPath}`;
  const previewPath = getPreviewPath(env);

  let redirect_uri = `https://www.medicare.gov/plan-compare${slsCallbackPath}`;

  if (env === Envs.local) {
    redirect_uri = `https://localhost${slsCallbackPath}`;
  } else if (env !== Envs.prod) {
    redirect_uri = `https://${env}.medicare.gov/plan-compare${previewPath}${slsCallbackPath}`;
  }

  return {
    client_id,
    mbp_uri,
    redirect_uri,
  };
};

/**
 * Checks for `sid`, only
 */
export const hasLocalSession = (): boolean =>
  !!document.cookie && document.cookie.includes("sid");

/**
 * Checks for `sid` and `state.beneficiary`.
 * This is a proxy for global session, and has nothing to do with an SLSx
 * global session cookie, which is not available to the front end
 */
export const hasGlobalSession = (state: AppState): boolean =>
  hasLocalSession() && !!state.beneficiary;

/**
 * Checks for `sid` and `beneficiary` (property from `AppState`)
 * This is a proxy for global session, and has nothing to do with an SLSx
 * global session cookie, which is not available to the front end
 */
export const hasSessionAndBene = (beneficiary: Beneficiary | undefined) =>
  hasLocalSession() && !!beneficiary;

const buildLoginUrl = (
  language: UserLanguage,
  redirectUri = "",
  relay: Record<string, unknown> | MBPLandingParams | undefined = {}
): URI => {
  const env = getEnvironment();

  const { mbp_uri, ...paramsToSend } = getLoginParams(env);

  URI.escapeQuerySpace = false;

  let query: Record<string, unknown> = {
    ...paramsToSend,
    lang: userToQueryStringLangMap[language],
  };

  if (redirectUri) {
    query = { ...query, redirect_uri: redirectUri };
  }

  const encoded = btoa(
    JSON.stringify({
      ...relay,
      lang: language,
    })
  );

  query = { ...query, relay: encoded };

  return new URI(mbp_uri).addQuery(query);
};

export const initiateLogin = (
  language: UserLanguage,
  redirectUri = "",
  relay: Record<string, unknown> | MBPLandingParams | undefined = undefined
): void => {
  const url = buildLoginUrl(language, redirectUri, relay);

  window.location.assign(url.toString());
};

export const getLoginUrl = (language: UserLanguage): string => {
  const url = buildLoginUrl(language, "", undefined);

  return url.toString();
};

export const getRegistrationUrl = (language: UserLanguage): string => {
  const env = getEnvironment();

  const domainPrefixes = {
    [Envs.local]: "test.",
    [Envs.test]: "test.",
    [Envs.dev]: "dev.",
    [Envs.imp]: "imp.",
    [Envs.prod]: "www.",
  };

  const prefix = domainPrefixes[env];

  const baseURL = "medicare.gov/account/create-account";
  const registrationURL = new URI(`https://${prefix}${baseURL}`);

  registrationURL.addQuery("lang", userToQueryStringLangMap[language]);

  return registrationURL.toString();
};

/**
 * @returns `boolean` if user is logged in
 */
export const getIsLoggedIn = ({
  beneficiary,
  location,
}: {
  beneficiary: Beneficiary | undefined;
  location: RouteComponentProps["location"];
}) => {
  const { mctt } = parseSearchParams(new URI(location.search).search(true));
  const isLoggedIn =
    hasSessionAndBene(beneficiary) &&
    ![
      MctToastType.LOGGED_OUT,
      MctToastType.SESSION_EXPIRED,
      MctToastType.CSR_BENE_FAIL,
      MctToastType.BENE_FAILED_LOGIN,
    ].includes(mctt as MctToastType);
  return isLoggedIn;
};

/**
 * Hook based on `getIsLoggedIn`
 * @returns `boolean` if user is logged in
 */
export function useLoggedIn() {
  const {
    state: { beneficiary },
  } = useContext(AppContext);
  const location = useLocation();
  const isLoggedIn = useMemo(
    () => getIsLoggedIn({ beneficiary, location }),
    [beneficiary, location]
  );
  return isLoggedIn;
}
