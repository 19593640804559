import React, { FC } from "react";
import { TooltipWithClose } from "..";
import { useTypedTranslate } from "../../helpers/intlHooks";
import { getReactNodeText, sendTooltipEvent } from "../../helpers";

export const TooltipPartDLep: FC = ({ children }) => {
  const t = useTypedTranslate();
  return (
    <TooltipWithClose
      contentHeading={
        <div
          className="ds-text-heading--lg ds-u-margin-bottom--0"
          role="heading"
          aria-level={2}
        >
          {t("ntm.tooltip.part_d_late_enrollment_penalty.heading")}
        </div>
      }
      title={t("ntm.tooltip.part_d_late_enrollment_penalty.body")}
      className="ds-c-tooltip__trigger-link"
      component="a"
      onOpen={() => sendTooltipEvent({ text: getReactNodeText(children) })}
    >
      {children}
    </TooltipWithClose>
  );
};
