import { useEffect, useState } from "react";

export const usePlaywrightEnv = () => {
  const [env, setEnv] = useState<Record<string, unknown>>({});
  useEffect(() => {
    for (const prop in window) {
      if (prop === "playwright") {
        console.debug(
          `window.playwright: ${JSON.stringify(window.playwright)}`
        );
        setEnv(window.playwright as Record<string, unknown>);
      }
    }
  }, []);
  return env;
};
