import React, { useEffect, useState } from "react";
import { ArrowIcon, Button } from "@cmsgov/ds-medicare-gov";
import stickybits from "stickybits";
import { toast, ToastContainer, Zoom } from "react-toastify";

interface StickyFooterProps {
  className?: string;
  id?: string;
  pageLoading?: boolean;
  isCollapsibleForMobile?: boolean;
  headingForisCollapsibleForMobile?: JSX.Element;
  errorMessage?: string;
}

const StickyFooter: React.FunctionComponent<StickyFooterProps> = ({
  isCollapsibleForMobile,
  headingForisCollapsibleForMobile,
  pageLoading,
  errorMessage,
  ...props
}) => {
  const TOAST_DURATION = 5000;
  const [isVisible, setIsVisible] = useState(true);
  const [stickyBit, setStickyBit] = useState<
    { update: () => void } | undefined
  >(undefined);

  const toggleVisibility = (): void => {
    setIsVisible(!isVisible);
  };

  const updateStickyBit = (): void => {
    if (stickyBit) {
      stickyBit.update();
      window.scrollBy(0, 1);
    }
  };

  useEffect(() => {
    const identifier = props.id ? `#${props.id}` : ".js-stickybits";
    setStickyBit(stickybits(identifier, { verticalPosition: "bottom" }));
    window.scrollBy(0, 1);
    window.addEventListener("resize", updateStickyBit);
    return (): void => window.removeEventListener("resize", updateStickyBit);
  }, []);

  useEffect(() => {
    updateStickyBit();
  }, [pageLoading]);

  errorMessage &&
    toast(
      <div className="ds-u-fill--primary-alt-lightest ds-u-padding--2">
        {errorMessage}
      </div>,
      {
        autoClose: TOAST_DURATION,
        className: "ds-u-padding--0",
        icon: false,
      }
    );

  return (
    <div
      {...props}
      id={props.id}
      className={`StickyFooter js-stickybits ${
        props.className ? props.className : ""
      }`}
      role="region"
      aria-live="polite"
      data-testid="StickyFooter"
      style={{ border: "none" }}
    >
      <ToastContainer
        autoClose={TOAST_DURATION}
        className="FooterToastContainer"
        closeOnClick={false}
        draggable={false}
        hideProgressBar
        icon={false}
        newestOnTop={false}
        position={toast.POSITION.BOTTOM_CENTER}
        rtl={false}
        transition={Zoom}
      />
      <div className="StickyFooter__content">
        {isCollapsibleForMobile ? (
          <div className={`Footer ${isVisible ? "" : "closed"}`}>
            <div className="Footer__header">
              {headingForisCollapsibleForMobile}
              <Button
                variation="ghost"
                size="small"
                className="FooterToggle"
                aria-hidden="true"
                onClick={toggleVisibility}
              >
                <ArrowIcon direction={isVisible ? "up" : "down"} />
              </Button>
            </div>
            <div className="Footer__list">{props.children}</div>
          </div>
        ) : (
          props.children
        )}
      </div>
    </div>
  );
};

export default StickyFooter;
