import React, { useEffect, useRef, useContext } from "react";
import { AppContext } from "../app/store";
import { SimpleFooter } from "@cmsgov/ds-medicare-gov";
import { useTranslate } from "../helpers";
import { getRouteName } from "../helpers/routeHelpers";
import { RouteComponentProps, withRouter } from "react-router";
import { AnalyticsActionType } from "../app/contexts/Analytics/types";

const Footer: React.FunctionComponent<RouteComponentProps> = ({ location }) => {
  const { state, dispatch } = useContext(AppContext);
  const t = useTranslate();
  const routeNameRef = useRef(getRouteName(location.pathname));

  useEffect(() => {
    const routeName = getRouteName(location.pathname);

    routeNameRef.current = routeName;
  }, [location.pathname]);

  return (
    <SimpleFooter
      language={state.language}
      aboutMedicareLabel={t("footer.about_medicare")}
      medicareGlossaryLabel={t("footer.medicare_glossary")}
      nondiscriminationLabel={t("footer.nondiscrimination_accessibility")}
      privacyPolicyLabel={t("footer.privacy_policy")}
      privacySettingLabel={t("footer.privacy_setting")}
      linkingPolicyLabel={t("footer.linking_policy")}
      usingThisSiteLabel={t("footer.using_this_site")}
      plainWritingLabel={t("footer.plain_writing")}
      websiteInfo={t("footer.website_info")}
      onClickLinkAnalytics={(url: string): void => {
        dispatch({
          type: AnalyticsActionType.SEND_TEALIUM_EVENT,
          settings: {
            event_action: routeNameRef.current,
            event_label: `Footer Link: ${url}`,
          },
        });
      }}
    />
  );
};

export default withRouter(Footer);
