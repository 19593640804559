import React, { FunctionComponent, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import URI from "urijs";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { parseSearchParams, useTranslate } from "../helpers";
import { MctToastType } from "../@types";
import { getRedirectForMedigapOrPlanFinder } from "../helpers/routeHelpers";

const RouteToastHandler: FunctionComponent<RouteComponentProps> = ({
  location,
  history,
  children,
}) => {
  const t = useTranslate();

  useEffect(() => {
    const url = new URI(location.search);
    const { mctt } = parseSearchParams(url.search(true));

    if (
      mctt !== undefined &&
      Object.values(MctToastType).includes(mctt as MctToastType)
    ) {
      let message = "";
      let method = "info";
      const options = {
        position: toast.POSITION.TOP_CENTER,
        containerId: "globalToastContainer",
      };

      if (mctt === MctToastType.BENE_FAILED_LOGIN) {
        message = "toast.login_failed";
        method = "error";
      } else if (mctt === MctToastType.LOGGED_OUT) {
        message = "toast.logged_out";
        method = "success";
      } else if (mctt === MctToastType.CSR_BENE_FAIL) {
        message = "error.could_not_find_beneficiary";
        method = "error";
      } else if (mctt === MctToastType.SESSION_EXPIRED) {
        message = "toast.session_expired";
        method = "error";
      }

      if (method === "info") {
        toast.info(t(message), options);
      } else if (method === "error") {
        toast.error(t(message), options);
      } else if (method === "success") {
        toast.success(t(message), options);
      }

      // After displaying the toast, remove from query so that it does not display again if the page is refreshed
      url.removeSearch("mctt");
      history.replace(url.toString());
    }
  }, [history, location.search, t]);

  useEffect(() => {
    const { location: globalWindowLocation } = window;

    // Switch between Medigap and Plan Finder if needed
    const redirectUrl = getRedirectForMedigapOrPlanFinder(
      new URL(globalWindowLocation.href)
    );

    if (redirectUrl) {
      globalWindowLocation.replace(redirectUrl);
    }
  }, [location]);

  return (
    <>
      <ToastContainer
        containerId="globalToastContainer"
        enableMultiContainer
        icon={false}
        transition={Zoom}
      />
      {children}
    </>
  );
};

export default withRouter(RouteToastHandler);
