import React, { useState, ChangeEvent, useEffect } from "react";
import { Button, Dropdown } from "@cmsgov/ds-medicare-gov";
import { useAppContext, useTranslate } from "../helpers";
import {
  DropdownProps,
  DropdownOptions,
} from "@cmsgov/design-system/dist/types/Dropdown/Dropdown";
import { ButtonProps } from "@cmsgov/design-system/dist/types/Button/Button";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../app/contexts/Analytics/types";
import classnames from "classnames";
import reactNodeToString from "react-node-to-string";
import { v1 as uuidv1 } from "uuid";

export interface SelectWithButtonProps
  extends Omit<
      DropdownProps,
      "children" | "onClick" | "className" | "onChange" | "ref"
    >,
    Partial<Pick<ButtonProps, "children">>,
    Pick<React.ComponentPropsWithoutRef<"div">, "className"> {
  buttonClassName?: string;
  onSelectApplied: (value: DropdownOptions["value"]) => void;
}

const uuid = uuidv1();

export const SelectWithButton = ({
  buttonClassName,
  children: buttonText,
  className = "",
  fieldClassName,
  labelClassName,
  label,
  name,
  onSelectApplied = () => {},
  options,
  ...props
}: SelectWithButtonProps) => {
  const t = useTranslate();
  const { dispatch } = useAppContext();

  const firstOption = options[0];

  // * State
  const [appliedValue, setAppliedValue] = useState(firstOption.value);
  const [fieldValue, setFieldValue] = useState(firstOption.value);
  const disableButton = fieldValue === appliedValue;

  const buttonTextValue = buttonText || t("select");
  const baseComponentClassName = "mct-c-select-with-button";
  const baseComponentId = `select-with-button-${uuid}`;
  const defaultSelectFieldClassName = `${baseComponentClassName}__select`;
  const selectFieldId = `${baseComponentId}__select`;
  const defaultButtonClassName = `${baseComponentClassName}__button`;
  const buttonId = `${baseComponentId}__button}`;

  const onChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    setFieldValue(event.target.value);
  };

  const updateAppliedValue = (value: DropdownOptions["value"]) => {
    setAppliedValue(value);
    onSelectApplied(value);
  };

  const onClickHandler = (): void => {
    updateAppliedValue(fieldValue);
    dispatch({
      type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
      settings: {
        button: {
          buttonStyle: AnalyticsButtonStyle.TRANSPARENT,
          buttonType: AnalyticsButtonType.BUTTON,
          text: reactNodeToString(buttonTextValue),
        },
      },
    });
  };

  // * Effects
  useEffect(() => {
    // This effect is to place the select element and button element directly next
    // to each other, wrapped in a single parent, for control over layout
    const baseComponentEl = document.getElementById(baseComponentId);
    const selectFieldEl = document.getElementById(selectFieldId);
    const buttonEl = document.getElementById(buttonId);
    if (selectFieldEl) {
      const selectFieldParentEl = selectFieldEl.parentElement;
      if (buttonEl && baseComponentEl && selectFieldParentEl) {
        const selectButtonWrapperEl = document.createElement("div");
        selectButtonWrapperEl.classList.add(
          `${baseComponentClassName}__select-button-container`
        );
        const wrapperElNode = selectFieldParentEl?.appendChild(
          selectButtonWrapperEl
        );
        const removedButtonNode = baseComponentEl.removeChild(buttonEl);
        const removedSelectNode =
          selectFieldParentEl?.removeChild(selectFieldEl);
        wrapperElNode?.appendChild(removedSelectNode);
        wrapperElNode?.appendChild(removedButtonNode);
      }
    }
  }, [baseComponentId, buttonId, selectFieldId]);

  return (
    <div
      className={classnames(baseComponentClassName, className)}
      role="group"
      id={baseComponentId}
    >
      <Dropdown
        value={fieldValue}
        fieldClassName={classnames(defaultSelectFieldClassName, fieldClassName)}
        label={label || t("select_an_option")}
        labelClassName={labelClassName}
        id={selectFieldId}
        name={name}
        onChange={onChangeHandler}
        options={options}
        {...props}
      />
      <Button
        className={classnames(defaultButtonClassName, buttonClassName)}
        disabled={disableButton}
        id={buttonId}
        isAlternate
        onClick={onClickHandler}
        variation="solid"
      >
        {buttonTextValue}
      </Button>
    </div>
  );
};
