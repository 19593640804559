import React from "react";
import { SearchResultPlan, PlanCategoryType } from "../../@types";
import { SuppressionHandler } from "..";
import {
  ifNumber,
  useTranslate,
  isMaPlanType,
  getDisplayAnnualDrugDeductible,
} from "../../helpers";
import { TooltipWithClose } from "../TooltipWithClose";
import { calculateYourResponsibility } from "../../helpers/planHelpers";
import { useLIS } from "../../helpers/context-hooks/useLIS";
import { sendPlanTooltipEvent } from "../../helpers/analytics-helpers/tooltip-analytics";

interface OtherCostsProps {
  plan: SearchResultPlan;
}

const OtherCosts: React.FunctionComponent<OtherCostsProps> = ({ plan }) => {
  const t = useTranslate();

  const { redactions } = plan;
  const { getLISForYear } = useLIS();
  const lisToUse = getLISForYear(+plan.contract_year);

  return (
    <div className="PlanCard__section" data-testid="otherCosts">
      <h3 className="PlanCard__section_heading">
        {t("plan_card.section_heading.other_costs")}
      </h3>
      <div className="PlanCard__info_group__otherCosts">
        <div className="PlanCard__data ds-u-display--inline">
          <SuppressionHandler redactions={redactions}>
            {ifNumber(plan.annual_deductible)}
          </SuppressionHandler>
        </div>
        <TooltipWithClose
          title={t(
            plan.category === PlanCategoryType.MSA
              ? "msa_plan.health_deductible_tooltip"
              : "plan_card.tooltip.health_deductible"
          )}
          onOpen={() =>
            sendPlanTooltipEvent(plan, t("plan_terms.health_plan_deductible"))
          }
        >
          {t("plan_terms.health_plan_deductible")}
        </TooltipWithClose>
      </div>
      {plan.category === PlanCategoryType.MSA && (
        <>
          <div className="PlanCard__info_group">
            <div className="PlanCard__data ds-u-display--inline">
              <SuppressionHandler redactions={redactions}>
                {ifNumber(plan.msa_annual_deposit)}
              </SuppressionHandler>
            </div>
            <TooltipWithClose title={t("msa_plan.yearly_deposit_tooltip")}>
              {t("msa_plan.yearly_deposit")}
            </TooltipWithClose>
          </div>
          <div className="PlanCard__info_group">
            <div className="PlanCard__data ds-u-display--inline">
              <SuppressionHandler redactions={redactions}>
                {ifNumber(
                  calculateYourResponsibility(
                    plan.annual_deductible,
                    plan.msa_annual_deposit
                  )
                )}
              </SuppressionHandler>
            </div>
            <TooltipWithClose title={t("msa_plan.your_responsibility_tooltip")}>
              {t("msa_plan.your_responsibility")}
            </TooltipWithClose>
          </div>
        </>
      )}
      {isMaPlanType(plan.plan_type) ? (
        <div className="PlanCard__info_group">
          <span>
            {t("plan_terms.drug_deductible")}
            {": "}
          </span>
          <span>
            {" "}
            {[
              PlanCategoryType.COST_1876,
              PlanCategoryType.PFFS,
              PlanCategoryType.MSA,
            ].includes(plan.category)
              ? t("plan_card.pffs_cost.drug_deductible")
              : t("plan_terms.see_what_drugs_are_covered_ma")}
          </span>
        </div>
      ) : (
        <div className="PlanCard__info_group">
          <div className="PlanCard__data ds-u-display--inline">
            <SuppressionHandler redactions={redactions}>
              {getDisplayAnnualDrugDeductible({
                lisToUse,
                drugPlanDeductible: plan.drug_plan_deductible,
                contractYear: plan.contract_year,
              })}
            </SuppressionHandler>
          </div>
          <TooltipWithClose
            title={t("plan_card.tooltip.drug_deductible")}
            onOpen={() =>
              sendPlanTooltipEvent(plan, t("plan_terms.drug_deductible"))
            }
          >
            {t("plan_terms.drug_deductible")}
          </TooltipWithClose>
        </div>
      )}
      <div className="PlanCard__info_group">
        <span className="PlanCard__data ds-u-margin-right--1">
          <SuppressionHandler redactions={redactions}>
            <span dangerouslySetInnerHTML={{ __html: plan.maximum_oopc }} />
          </SuppressionHandler>
        </span>
        <TooltipWithClose
          title={t("plan_card.tooltip.oopc_maximum")}
          onOpen={() =>
            sendPlanTooltipEvent(plan, t("plan_terms.oopc_maximum"))
          }
        >
          {t("plan_terms.oopc_maximum")}
        </TooltipWithClose>
      </div>
    </div>
  );
};

export default OtherCosts;
