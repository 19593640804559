import React, { FC, useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { AlertWithCustomIcon, IDCardIcon } from "..";
import { AppContext } from "../../app/store";
import {
  IntlContext,
  getLinkToMedicare,
  isMedicareAdvantageType,
} from "../../helpers";
import { PlanType } from "../../@types";
import { TranslationKey } from "../../helpers/intlHooks";
import { WarningIcon } from "@cmsgov/ds-medicare-gov";
import { AlertProps } from "@cmsgov/design-system/dist/types/Alert/Alert";
import classNames from "classnames";
import { AnalyticsActionType } from "../../app/contexts/Analytics/types";
import { useFormattedMessageRemoveCustomTags } from "../../helpers/formattedMessageHelpers";

export const NeedsMedicareABAlert: FC<
  Omit<AlertProps, "variation"> & {
    headingKey: TranslationKey;
    bodyKey: TranslationKey;
    showAsError?: boolean;
  }
> = ({ headingKey, bodyKey, showAsError = false, className, ...props }) => {
  const {
    state: { language, planType },
    dispatch,
  } = useContext(AppContext);
  const intl = useContext(IntlContext);
  const isMAPlan = !!planType && isMedicareAdvantageType(planType);
  const isPDPlan = planType === PlanType.PDP;
  // Must be a string, so use `intl.formatMessage.toString()`
  const needsMedicareABHeading: string = intl
    .formatMessage(
      {
        id: headingKey,
      },
      {
        pdp: chunks => (isPDPlan ? chunks : ""),
        ma: chunks => (isMAPlan ? chunks : ""),
      }
    )
    .toString();

  const alertContentText = useFormattedMessageRemoveCustomTags(bodyKey, [
    { tag: "a" },
    { tag: "pdp", handler: chunks => (isPDPlan ? chunks : "") },
    { tag: "ma", handler: chunks => (isMAPlan ? chunks : "") },
    { tag: "strong" },
  ]);

  useEffect(() => {
    dispatch({
      type: AnalyticsActionType.SEND_ALERT_IMPRESSION_EVENT,
      settings: {
        heading: needsMedicareABHeading,
        text: alertContentText,
        type: showAsError ? "error" : "informative",
      },
    });
  }, [alertContentText, dispatch, needsMedicareABHeading, showAsError]);

  const whenCanISignUpForMedicareHref = getLinkToMedicare({
    language,
    mgovUrlKey: "whenCanISignUpForMedicare",
  });

  return (
    <AlertWithCustomIcon
      heading={needsMedicareABHeading}
      Icon={showAsError ? WarningIcon : IDCardIcon} // The error version uses a warning icon
      className={classNames("ds-u-margin-bottom--2", className)}
      variation={showAsError ? "error" : undefined}
      {...props}
    >
      <FormattedMessage
        id={bodyKey}
        values={{
          a: chunks => (
            <a
              href={whenCanISignUpForMedicareHref}
              onClick={() => {
                dispatch({
                  type: AnalyticsActionType.SEND_INTERNAL_LINK_CLICKED_EVENT,
                  settings: {
                    linkText: String(chunks),
                    linkUrl: whenCanISignUpForMedicareHref,
                    text: String(chunks),
                  },
                });
              }}
              target="_blank"
              rel="noreferrer"
            >
              {chunks}
            </a>
          ),
          pdp: chunks => (isPDPlan ? chunks : ""),
          ma: chunks => (isMAPlan ? chunks : ""),
          strong: chunks => <strong>{chunks}</strong>,
        }}
      />
    </AlertWithCustomIcon>
  );
};
