import React, {
  useState,
  FormEvent,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import classNames from "classnames";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  CoverageSelectorFormValues,
  CoverageSelectorCoverageType,
  CoverageSelectorErrorType,
  isCoverageSelectorSelectedValues,
  ActionType,
  SharedCoverageSelectorProps,
  SharedCoverageSelectorAnalyticsValues,
} from "../../@types";
import { useTranslate } from "../../helpers/intlHooks";
import { isValidZipcode } from "../../helpers/zipcodeHelpers";
import {
  getYearPartInfo,
  useCurrentPlanYear,
} from "../../helpers/yearFlagHelpers";
import {
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../../app/contexts/Analytics/types";
import { BadgeWithIcon, ContinueWithoutLoggingInIcon } from "../../components";
import {
  PlanTypeStep,
  PlanTypeStepActions,
  ZipcodeAndCoverageYearStep,
  ZipcodeAndCoverageYearStepActions,
} from "./components";
import {
  sendCoverageSelectorAnalytics,
  useInitialFormValues,
  useCoverageSelectorDestinationMap,
  updateStates,
} from "../../helpers";
import { useHistory } from "react-router";
import { useAppContext } from "../../helpers/context-hooks";
import { CoverageSelectorAlert } from "./CoverageSelectorAlert";
import { useCounties } from "../../helpers/query-hooks/useCounties";

enum ActiveStep {
  ZIPCODE,
  PLAN_TYPE,
}

interface CoverageSelectorV2Props
  extends SharedCoverageSelectorProps,
    SharedCoverageSelectorAnalyticsValues {
  isInModal?: boolean;
  handleCancel?: () => void;
  /** set to `true` if you do not want there to be multiple steps. */
  isSinglePage?: boolean;
  /** set to `true` to show an alert that warns the user that they are continuing without logging in. */
  showContinueWithoutLoggingIn?: boolean;
  /** custom logic to run when the user clicks to "Find Plans" */
  onFindPlans?: () => void;
  /** skip straight to coverage type if we already have zipcode */
  skipZipcode?: boolean;
  /** allows the component to have pre-selected choices. */
  defaultFormValues?: Partial<CoverageSelectorFormValues>;
}

export const CoverageSelectorV2: React.FunctionComponent<
  CoverageSelectorV2Props
> = ({
  isInModal = false,
  handleCancel,
  isSinglePage,
  showDescription,
  newToMedicare,
  showContinueWithoutLoggingIn,
  onFindPlans,
  analyticsEventAction,
  skipZipcode,
  defaultFormValues,
}) => {
  // * Hooks
  const t = useTranslate();
  const history = useHistory();
  const { initialFormValues } = useInitialFormValues();
  const destinationMap = useCoverageSelectorDestinationMap();
  const { dispatch } = useAppContext();

  // * Flags
  const flags = useFlags();
  const { isPublicPreview, isOpenEnrollment, isOpenEnrollmentNextYearOnly } =
    getYearPartInfo(flags);
  const mctCurrentPlanYear = useCurrentPlanYear();
  const showCoverageYearSelector =
    isPublicPreview || isOpenEnrollment || isOpenEnrollmentNextYearOnly;

  // * States
  const [showZipcodeErrors, setShowZipcodeErrors] = useState(false);
  const [showCoverageTypeErrors, setShowCoverageTypeErrors] = useState(false);
  const [currentFormValues, setCurrentFormValues] =
    useState<CoverageSelectorFormValues>({
      coverageYear: showCoverageYearSelector
        ? mctCurrentPlanYear + 1
        : mctCurrentPlanYear,
      zipcode: initialFormValues?.zipcode,
      ...defaultFormValues,
    });
  const [activeStep, setActiveStep] = useState<ActiveStep>(
    skipZipcode && initialFormValues?.zipcode && initialFormValues?.fips
      ? ActiveStep.PLAN_TYPE
      : ActiveStep.ZIPCODE
  );

  // * Queries
  const { counties, isError } = useCounties(currentFormValues.zipcode);

  // * Refs
  const topOfPageRef = useRef<HTMLDivElement>(null);

  // * Constants
  const url = window.location.href.split("/?")[0];
  const destination =
    currentFormValues.coverageType &&
    destinationMap[currentFormValues.coverageType];
  const linkUrl = `${url}${destination}${location.search}`;
  const findPlansButtonText = newToMedicare
    ? t("next")
    : t("coverage_selector.find_plans");
  const analyticsButton = useMemo(
    () => ({
      text: findPlansButtonText,
      buttonStyle: AnalyticsButtonStyle.PRIMARY,
      buttonType: AnalyticsButtonType.SUBMIT,
    }),
    [findPlansButtonText]
  );
  const hasCounty = !!currentFormValues.county;
  const hasCoverageType = !!currentFormValues.coverageType;
  const initialFips = initialFormValues?.fips;

  // * Memos
  const zipcodeErrorState = useMemo(() => {
    if (isError)
      // we always show an API error, even if `showZipcodeErrors` is false.
      return {
        error: CoverageSelectorErrorType.API,
        message: t("coverage_selector.error.zip_code_not_found"),
      };
    if (!showZipcodeErrors) return;
    if (!isValidZipcode(currentFormValues.zipcode)) {
      return {
        error:
          currentFormValues.zipcode?.length === 0
            ? CoverageSelectorErrorType.MISSING
            : CoverageSelectorErrorType.INVALID,
        message: t("coverage_selector.error.zip_code"),
      };
    }
    return;
  }, [currentFormValues.zipcode, isError, showZipcodeErrors, t]);

  const countiesErrorState = useMemo(() => {
    if (!showZipcodeErrors || zipcodeErrorState || currentFormValues.county)
      return;
    return {
      error: CoverageSelectorErrorType.MISSING,
      message: t("coverage_selector.error.missing_county"),
    };
  }, [currentFormValues.county, t, zipcodeErrorState, showZipcodeErrors]);

  const planTypeErrorState = useMemo(() => {
    if (!showCoverageTypeErrors || currentFormValues.coverageType) return;
    return {
      error: CoverageSelectorErrorType.MISSING,
      message: t("coverage_selector.error.plan_type"),
    };
  }, [showCoverageTypeErrors, currentFormValues.coverageType, t]);

  const errorState = useMemo(
    () => ({
      zipcode: zipcodeErrorState,
      planType: planTypeErrorState,
      counties: countiesErrorState,
    }),
    [countiesErrorState, planTypeErrorState, zipcodeErrorState]
  );

  const defaultCounty = useMemo(() => {
    // returns a matching county if we have a fips in state.
    if (initialFips) {
      const foundCounty = counties.find(county => county.fips === initialFips);
      if (foundCounty) return foundCounty;
    }
    if (counties.length === 1) {
      // returns the only county if there is no other option
      return counties[0];
    }
    return;
  }, [counties, initialFips]);

  // * Functions
  const onZipcodeChange = useCallback(
    (zipcodeInput: string) => {
      setShowZipcodeErrors(false);
      setCurrentFormValues(currentFormValues => ({
        ...currentFormValues,
        zipcode: zipcodeInput,
        county: undefined,
      }));
      if (isValidZipcode(zipcodeInput)) {
        dispatch({
          type: ActionType.UPDATE_ZIPCODE,
          payload: zipcodeInput,
        });
      }
    },
    [dispatch]
  );

  const onClickContinue = (e?: FormEvent<HTMLFormElement>): void => {
    e?.preventDefault();
    triggerErrors();
    if (hasCounty) {
      dispatch({
        type: ActionType.UPDATE_FIPS,
        payload: currentFormValues.county?.fips,
      });
      setActiveStep(ActiveStep.PLAN_TYPE);
    }
  };

  const navigateToPlans = useCallback(() => {
    if (destination) {
      history.push(destination);
    }
  }, [destination, history]);

  const handleFindPlans = useCallback(() => {
    // * Run any custom logic for this instance
    if (onFindPlans) {
      onFindPlans();
    }
    // * Update AppContext with selected values
    if (isCoverageSelectorSelectedValues(currentFormValues)) {
      updateStates(currentFormValues, dispatch);
    }
    if (currentFormValues.coverageType) {
      sendCoverageSelectorAnalytics({
        analyticsButton,
        analyticsEventAction,
        currentFormValues,
        dispatch,
        linkUrl,
      });
      navigateToPlans();
    }
  }, [
    onFindPlans,
    currentFormValues,
    dispatch,
    analyticsButton,
    analyticsEventAction,
    linkUrl,
    navigateToPlans,
  ]);

  const onClickStart = (e: FormEvent<HTMLFormElement>): void => {
    /** adding 16px to the top to keep the page looking a bit nice after the scroll. */
    const buffer = 16;
    e.preventDefault();
    triggerErrors();
    if (isSinglePage) {
      if (hasCounty && hasCoverageType) {
        handleFindPlans();
      } else {
        if (topOfPageRef.current) {
          window.scrollTo({
            top: topOfPageRef.current.offsetTop - buffer,
            left: 0,
            behavior: "smooth",
          });
        }
      }
      return;
    }
    if (activeStep === ActiveStep.PLAN_TYPE) {
      if (hasCoverageType) {
        handleFindPlans();
      }
    } else {
      onClickContinue();
    }
  };

  const goBackToZipCode = isSinglePage
    ? undefined
    : () => {
        setActiveStep(ActiveStep.ZIPCODE);
        setShowCoverageTypeErrors(false);
        setCurrentFormValues(currentForm => ({
          ...currentForm,
          coverageType: undefined,
        }));
      };

  const onPlanTypeChange = (planType: CoverageSelectorCoverageType) => {
    setCurrentFormValues({
      ...currentFormValues,
      coverageType: planType,
    });
  };

  const triggerErrors = () => {
    if (activeStep === ActiveStep.ZIPCODE || isSinglePage) {
      setShowZipcodeErrors(true);
    }
    if (activeStep === ActiveStep.PLAN_TYPE || isSinglePage) {
      setShowCoverageTypeErrors(true);
    }
  };

  // * Effects

  useEffect(() => {
    // if `defaultCounty` changes, assign it to `currentFormValues`.
    setCurrentFormValues(currentFormValues => ({
      ...currentFormValues,
      county: defaultCounty,
    }));
  }, [defaultCounty]);

  return (
    <>
      {activeStep === ActiveStep.ZIPCODE && showContinueWithoutLoggingIn && (
        <BadgeWithIcon
          svgIcon={ContinueWithoutLoggingInIcon}
          theme="primary-alt-lightest"
          className="ds-u-margin-bottom--2"
        >
          {t("landing_page.badge.continue_without_logging_in")}
        </BadgeWithIcon>
      )}
      <div
        className={classNames("mct-c-coverage-selector-v2", {
          "mct-c-coverage-selector-v2--in-modal": isInModal,
        })}
        data-testid="mct-c-coverage-selector-v2"
        ref={topOfPageRef}
      >
        <form onSubmit={onClickStart}>
          <CoverageSelectorAlert errorState={errorState} />
          <div className="mct-c-coverage-selector-v2__step">
            {(activeStep === ActiveStep.ZIPCODE || isSinglePage) && (
              <ZipcodeAndCoverageYearStep
                isSinglePage={isSinglePage}
                countiesErrorState={countiesErrorState}
                zipcodeErrorState={zipcodeErrorState}
                currentFips={currentFormValues.county?.fips}
                initialFips={initialFips}
                onCountyChange={county => {
                  setCurrentFormValues(formValues => ({
                    ...formValues,
                    county: JSON.parse(county),
                  }));
                }}
                onCoverageYearChange={coverageYear => {
                  setCurrentFormValues(formValues => ({
                    ...formValues,
                    coverageYear: +coverageYear,
                  }));
                }}
                onZipcodeChange={onZipcodeChange}
                selectedCoverageYear={currentFormValues.coverageYear}
                selectedZipcode={currentFormValues.zipcode}
                zipcode={currentFormValues.zipcode}
              />
            )}
            {(activeStep === ActiveStep.PLAN_TYPE || isSinglePage) && (
              <PlanTypeStep
                showDescription={showDescription}
                planTypeErrorState={errorState.planType}
                onPlanTypeChange={onPlanTypeChange}
                newToMedicare={newToMedicare}
                isSinglePage={isSinglePage}
                selectedPlanType={currentFormValues.coverageType}
              />
            )}
          </div>
          {isSinglePage && <div className="mct-c-divider" />}
          <div className={isSinglePage ? "" : "ds-u-padding-top--2"}>
            {activeStep === ActiveStep.ZIPCODE && !isSinglePage ? (
              <ZipcodeAndCoverageYearStepActions
                onClickContinue={onClickContinue}
                isInModal={isInModal}
                handleCancel={handleCancel}
              />
            ) : (
              <PlanTypeStepActions
                findPlansButtonText={findPlansButtonText}
                handleGoBack={goBackToZipCode}
              />
            )}
          </div>
        </form>
      </div>
    </>
  );
};
