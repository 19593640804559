import { getPlan } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "./types";
import { parsePlanLongIdIntoUrlPartForApi } from "../objectUtilities";
import { useRouteParams } from "../routing-hooks";
import { LongPlanId } from "../routing-hooks/types";
import { isValidApiLongPlanId } from "../planHelpers";
import { useLIS } from "../context-hooks/useLIS";
import { Plan } from "../../@types";
import { useMemo } from "react";
import { useAppContext } from "../context-hooks";
import { useCurrentPlanYear } from "../yearFlagHelpers";

export interface UsePlanPayload {
  plan?: Plan;
  isInitialLoading: boolean;
  error?: Error;
}

type UsePlanType = (overrides?: {
  planId?: LongPlanId;
  lisYear?: number;
}) => UsePlanPayload;

/**
 * query hook which fetches a plan
 * @example const plan = usePlan(); // will grab the plan based of the planId in the route
 * @example const plan = usePlan({planId}); // will grab a specific plan
 */
export const usePlan: UsePlanType = (overrides?: {
  planId?: LongPlanId;
  lisYear?: number;
}) => {
  // * Context
  const {
    state: { year },
  } = useAppContext();
  const mctCurrentPlanYear = useCurrentPlanYear();
  const { getLISForYear } = useLIS();

  // * Derived from context
  const stateYear = year ? Number(year) : null;

  // * Route
  const { planId: routePlanId } = useRouteParams();

  // * Constants
  const longPlanId = overrides?.planId || routePlanId;
  const apiPlanId = longPlanId
    ? parsePlanLongIdIntoUrlPartForApi(longPlanId)
    : "";

  const planIdIsValid = isValidApiLongPlanId(apiPlanId);

  // will use `state.year` as a fallback and `mctCurrentPlanYear` if state has been cleared
  const lisToSend = getLISForYear(
    overrides?.lisYear || stateYear || mctCurrentPlanYear
  );

  // * Queries
  const {
    data,
    error: queryError,
    isInitialLoading,
  } = useQuery({
    queryKey: [QueryKey.Plan, apiPlanId, lisToSend],
    queryFn: () => {
      return getPlan(apiPlanId, lisToSend ? { lis: lisToSend } : {});
    },
    enabled: planIdIsValid,
  });

  const error = useMemo(() => {
    // error comes from calling the endpoint and not getting a plan back
    if (queryError) return queryError as Error;
    if (!planIdIsValid) {
      // error comes from invalid plan id, where we do not call the endpoint in the first place
      return new Error(`Invalid plan ID (${apiPlanId})`);
    }
    return;
  }, [apiPlanId, planIdIsValid, queryError]);

  return {
    plan: data,
    isInitialLoading,
    error,
  };
};
