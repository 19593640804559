import React, { FunctionComponent, useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button } from "@cmsgov/ds-medicare-gov";
import { useTranslate } from "../helpers/intlHooks";
import { AppContext } from "../app/store";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../app/contexts/Analytics/types";

export interface ContinueProps extends RouteComponentProps {
  to: string;
  onClick?: () => void;
  useLink?: boolean;
  disabled?: boolean;
}

const Continue: FunctionComponent<ContinueProps> = ({
  to,
  onClick,
  useLink,
  disabled,
  children,
  history,
}) => {
  const t = useTranslate();
  const { dispatch } = useContext(AppContext);

  const handleClick = (): void => {
    dispatch({
      type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
      settings: {
        button: {
          buttonStyle: AnalyticsButtonStyle.PRIMARY,
          buttonType: AnalyticsButtonType.BUTTON,
          text: children ? children.toString() : t("landing_page.continue"),
        },
        linkUrl: to,
      },
    });
    history.push(to);
  };

  return (
    <section className="ContinueSection ds-u-display--flex ds-u-flex-wrap--wrap">
      {useLink ? (
        <a
          className="ds-c-button ds-c-button--solid mct-u-width--full mct-u-md-width--auto"
          data-testid="continue"
          href={to}
        >
          {children ? children : t("landing_page.continue")}
        </a>
      ) : (
        <Button
          disabled={disabled}
          variation="solid"
          className="e2e-continue-button mct-u-width--full mct-u-md-width--auto"
          onClick={onClick || handleClick}
          data-testid="continue"
        >
          {children ? children : t("landing_page.continue")}
        </Button>
      )}
    </section>
  );
};

export default withRouter(Continue);
