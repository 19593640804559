import { Button, Dialog } from "@cmsgov/ds-medicare-gov";
import React, { FunctionComponent, useContext, useState } from "react";
import URI from "urijs";
import { ActionType, County } from "../@types";
import { AppStore } from "../app/store";
import { useTranslate } from "../helpers";
import { CountyPicker } from "../components";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
  TealiumEventProps,
} from "../app/contexts/Analytics/types";
import { useHistory } from "react-router";
import { useLocation } from "react-router";

interface ChangeLocationModalProps {
  onModalClose: () => void;
  className?: string;
  receiveCountyZip?: (county: County, zip: string) => void;
  tealiumSaveProps?: TealiumEventProps;
}
export const ChangeLocationModal: FunctionComponent<
  ChangeLocationModalProps
> = ({
  onModalClose,
  className = "ChangeLocationModal",
  receiveCountyZip,
  tealiumSaveProps,
  ...props
}) => {
  const { dispatch, state } = useContext(AppStore.AppContext);
  const history = useHistory();
  const location = useLocation();
  const t = useTranslate();
  const [newLocation, setNewLocation] = useState<{
    county: County;
    zipcode: string;
  } | null>(null);

  const handleLocationSelection = (county?: County, zipcode?: string): void => {
    if (county && county.fips && zipcode) {
      setNewLocation({ county, zipcode });
    } else {
      setNewLocation(null);
    }
  };

  /* istanbul ignore next: tested elsewhere */
  const updateLocation = (): void => {
    if (newLocation) {
      const { county, zipcode } = newLocation;
      let newUrl = undefined;
      if (receiveCountyZip) {
        receiveCountyZip(county, zipcode);
      } else {
        dispatch({ type: ActionType.UPDATE_COUNTY, payload: county });
        dispatch({ type: ActionType.UPDATE_FIPS, payload: county.fips });
        dispatch({ type: ActionType.UPDATE_ZIPCODE, payload: zipcode });

        if (tealiumSaveProps) {
          const { event_action, event_label } = tealiumSaveProps;
          dispatch({
            type: AnalyticsActionType.SEND_TEALIUM_EVENT,
            settings: {
              event_action,
              event_label,
            },
          });
        }

        const currentUrl = new URI(location.search);
        const { plan_type } = currentUrl.search(true);
        newUrl = new URI(location.pathname);
        newUrl.addSearch("fips", county.fips);
        newUrl.addSearch("zipcode", zipcode);
        newUrl.addSearch("plan_type", plan_type || state.planType);
        history.push(newUrl.toString());
      }
      dispatch({
        type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
        settings: {
          button: {
            buttonStyle: AnalyticsButtonStyle.PRIMARY,
            buttonType: AnalyticsButtonType.BUTTON,
            text: t("save"),
          },
          linkUrl: newUrl?.toString(),
        },
      });
      setNewLocation(null);
      onModalClose();
    }
  };
  return (
    <Dialog
      onExit={onModalClose}
      heading={t("pharmacy_selection.change_location.title")}
      className={className}
      headerClassName={`${className}__header`}
      {...props}
      actions={[
        <Button
          className="ds-u-margin-right--2"
          key="primary"
          variation="solid"
          disabled={!newLocation}
          onClick={updateLocation}
        >
          {t("save")}
        </Button>,
        <Button
          variation="solid"
          className={"ds-u-text-decoration--none"}
          key="cancel"
          onClick={() => {
            dispatch({
              type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
              settings: {
                button: {
                  buttonStyle: AnalyticsButtonStyle.PRIMARY,
                  buttonType: AnalyticsButtonType.BUTTON,
                  text: t("cancel"),
                },
              },
            });
            onModalClose();
          }}
        >
          {t("cancel")}
        </Button>,
      ]}
    >
      <p className="ChangeLocationModal__message">
        {t("change_location.message")}
      </p>
      <CountyPicker
        label={t("pharmacy_selection.change_location.label")}
        onCountySelect={handleLocationSelection}
        className="ds-u-margin-bottom--6"
      />
    </Dialog>
  );
};
