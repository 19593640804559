import { Alert, Button, Choice, InlineError } from "@cmsgov/ds-medicare-gov";
import React, { useRef, useState } from "react";
import { useTypedTranslate } from "../../helpers/intlHooks";
import { LowIncomeSubsidyOption } from "../../@types";
import { FormattedMessage } from "react-intl";
import { LISProgramsHelpDrawer } from "./LISProgramsHelpDrawer";
import { sendInlineValidationErrorAnalyticsEvent } from "../../app/contexts/Analytics/helpers";
import { getLoginUrl } from "../../helpers/loginHelpers";
import { getUserOptionFromLISCode, useAppContext } from "../../helpers";

interface LISQuestionsUpdateProps {
  onClickLogin: () => void;
  onClickContinue: (selectedHelpType: LowIncomeSubsidyOption) => void;
}

const HelpChoice = ({
  header,
  description,
}: {
  header: string;
  description?: string;
}) => {
  return (
    <div className="ds-u-margin-bottom--2">
      <span className="mct-p-lis-questions__choice-header">{header}</span>
      {description && <p className="ds-u-margin-top--0">{description}</p>}
    </div>
  );
};

export const LISQuestionsUpdate = ({
  onClickLogin,
  onClickContinue,
}: LISQuestionsUpdateProps) => {
  const t = useTypedTranslate();
  const { state } = useAppContext();
  const [selectedHelpType, setSelectedHelpType] = useState<
    LowIncomeSubsidyOption | undefined
  >(getUserOptionFromLISCode(state.lis));
  const [showError, setShowError] = useState(false);
  const firstRadioRef = useRef<HTMLInputElement | null>(null);

  const handleHelpSelection = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedHelpType(e.target.value as LowIncomeSubsidyOption);
    setShowError(false);
  };

  const handleContinue = () => {
    if (selectedHelpType) {
      onClickContinue(selectedHelpType);
    } else {
      setShowError(true);
      sendInlineValidationErrorAnalyticsEvent({
        fieldName: "help_with_your_costs.help_type",
        errorMessage: t("extra_help_page.update.no_selection_error"),
      });
      if (firstRadioRef.current) {
        firstRadioRef.current.focus();
      }
    }
  };
  return (
    <div className="mct-p-lis-questions__content">
      <p className="ds-u-margin-top--0">
        {t("extra_help_page.update.overview.line1")}
      </p>
      <p>
        <FormattedMessage
          id="extra_help_page.update.overview.line2"
          values={{
            b: chunks => <strong>{chunks}</strong>,
            link: chunks => (
              <a onClick={onClickLogin} href={getLoginUrl(state.language)}>
                {chunks}
              </a>
            ),
          }}
        />
      </p>
      {showError && (
        <Alert heading={t("coverage_selector.alert.heading")} variation="error">
          {t("coverage_selector.alert.message")}
        </Alert>
      )}
      <fieldset className="ds-u-border--0 ds-u-margin-left--0 ds-u-padding-left--0">
        <Choice
          name="helpType"
          type="radio"
          value={LowIncomeSubsidyOption.Medicaid}
          label={
            <HelpChoice
              header={t("landing_page.lis.medicaid_help_label")}
              description={t(
                "extra_help_page.update.choice.medicaid.description"
              )}
            />
          }
          onChange={handleHelpSelection}
          inputRef={(ref: HTMLInputElement): void => {
            firstRadioRef.current = ref;
          }}
          checked={selectedHelpType === LowIncomeSubsidyOption.Medicaid}
        />
        <Choice
          name="helpType"
          type="radio"
          value={LowIncomeSubsidyOption.SSI}
          label={<HelpChoice header={t("landing_page.lis.ssi_help_label")} />}
          onChange={handleHelpSelection}
          checked={selectedHelpType === LowIncomeSubsidyOption.SSI}
        />
        <Choice
          name="helpType"
          type="radio"
          value={LowIncomeSubsidyOption.MSP}
          label={<HelpChoice header={t("landing_page.lis.msp_help_label")} />}
          onChange={handleHelpSelection}
          checked={selectedHelpType === LowIncomeSubsidyOption.MSP}
        />
        <Choice
          name="helpType"
          type="radio"
          value={LowIncomeSubsidyOption.SS}
          label={
            <HelpChoice header={t("extra_help_page.update.ss_help_label")} />
          }
          onChange={handleHelpSelection}
          checked={selectedHelpType === LowIncomeSubsidyOption.SS}
        />
        <FormattedMessage
          id="extra_help_page.update.not_sure"
          values={{
            b: chunks => <strong>{chunks}</strong>,
            link: chunks => (
              <LISProgramsHelpDrawer
                toggleText={String(chunks)}
                onClickLogin={onClickLogin}
              />
            ),
          }}
        />

        <hr className="ds-u-margin-bottom--2 ds-u-margin-top--3" />
        <Choice
          name="helpType"
          type="radio"
          value={LowIncomeSubsidyOption.None}
          label={<HelpChoice header={t("landing_page.lis.no_help")} />}
          onChange={handleHelpSelection}
          checked={selectedHelpType === LowIncomeSubsidyOption.None}
        />
        {showError && (
          <InlineError>
            {t("extra_help_page.update.no_selection_error")}
          </InlineError>
        )}
      </fieldset>

      <Button
        className="ds-u-margin-top--3"
        variation="solid"
        onClick={handleContinue}
      >
        {t("plan_preferences_page.continue")}
      </Button>
    </div>
  );
};
