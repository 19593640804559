import Parser from "html-react-parser";
import {
  BenefitItemProps,
  NetworkCostDescriptors,
  Plan,
  PackageBenefit,
  NetworkCostDescriptor,
} from "../@types";
import { CONSTANTS } from "./CONSTANTS";
import { shouldSuppressPlanBenefits } from "./planHelpers";
import { $$ } from ".";

/* https://stackoverflow.com/questions/2631001/test-for-existence-of-nested-javascript-object-key for why the "unusual" syntax below.

It's one of the fastest options for accessing deeply-nested object properties safely, and doesn't require the addition of another dependency.
*/

export const getBenefitObject = (
  plan: Plan,
  benefit: string
): null | PackageBenefit => {
  if (!plan.package_benefits) {
    return null;
  }

  const packageBenefit =
    plan.package_benefits[`BENEFIT_${benefit.toUpperCase()}`];

  if (!packageBenefit || !packageBenefit.benefit_services) {
    return null;
  }

  return packageBenefit;
};

export const getBenefitService = (
  benefitObject: PackageBenefit,
  service: string
): { network_costs: NetworkCostDescriptors } | null => {
  const benefitService =
    benefitObject.benefit_services[`SERVICE_${service.toUpperCase()}`];

  return benefitService || null;
};

export const getCostShares = (
  t: (x: string) => string,
  networkCosts: NetworkCostDescriptors
): string => {
  if (networkCosts[`NETWORK_TYPE_NA`]) {
    return Parser(networkCosts[`NETWORK_TYPE_NA`].cost_share);
  }

  let costShare = networkCosts[`NETWORK_TYPE_IN_NETWORK`]
    ? `${t("plan_details.benefit.in_network")} ${
        networkCosts[`NETWORK_TYPE_IN_NETWORK`].cost_share
      }`
    : `${t("plan_details.benefit.in_network")} ${t(
        "plan_details.benefit.no_data"
      )}`;

  if (networkCosts[`NETWORK_TYPE_OUT_OF_NETWORK`]) {
    costShare += "<br/>";

    costShare += `${t("plan_details.benefit.oo_network")} ${
      networkCosts[`NETWORK_TYPE_OUT_OF_NETWORK`].cost_share
    }`;
  }

  return Parser(costShare);
};

export const getBenefitCost = (
  t: (x: string) => string,
  {
    benefit,
    plan,
    service,
  }: {
    benefit: string;
    plan: Plan;
    service?: string;
  }
): string => {
  const benefitObject = getBenefitObject(plan, benefit);

  if (!benefitObject) {
    return t("plan_terms.not_covered");
  }

  if (!service) {
    return getCostShares(t, benefitObject.network_costs);
  }

  const benefitService = getBenefitService(benefitObject, service);
  if (!benefitService) {
    return t("plan_terms.not_covered");
  }
  return getCostShares(t, benefitService.network_costs);
};

export const PLAN_HAS_NO_LIMITS: NetworkCostDescriptor = {
  authorization_required: false,
  referral_required: false,
  plan_limits: false,
  cost_share: "",
};

export const getLimits = (
  networkCosts: NetworkCostDescriptors
): NetworkCostDescriptor => {
  const notAvailableNetwork = networkCosts["NETWORK_TYPE_NA"];
  const inNetwork = networkCosts["NETWORK_TYPE_IN_NETWORK"];
  const outOfNetwork = networkCosts["NETWORK_TYPE_OUT_OF_NETWORK"];

  const costs = [notAvailableNetwork, inNetwork, outOfNetwork].filter(o => !!o);

  return costs.length
    ? {
        authorization_required: !!costs.find(c => c.authorization_required),
        referral_required: !!costs.find(c => c.referral_required),
        plan_limits: !!costs.find(c => c.plan_limits),
        cost_share: "",
      }
    : PLAN_HAS_NO_LIMITS;
};

export const getBenefitExclusionsAndLimitations = ({
  plan,
  benefit,
  service,
}: {
  plan: Plan;
  benefit: string;
  service?: string;
}): NetworkCostDescriptor => {
  const benefitObject = getBenefitObject(plan, benefit);
  if (!benefitObject) {
    return PLAN_HAS_NO_LIMITS;
  }

  if (!service) {
    return getLimits(benefitObject.network_costs);
  }

  const benefitService = getBenefitService(benefitObject, service);
  if (!benefitService) {
    return PLAN_HAS_NO_LIMITS;
  }

  return getLimits(benefitService.network_costs);
};

export const hasSomeDentalCoverage = (
  t: (key: string) => string,
  plan: Plan
): boolean => {
  return CONSTANTS.dentalServices.some(
    service =>
      getBenefitCost(t, {
        plan,
        benefit: "comprehensive_dental",
        service,
      }) !== t("plan_terms.not_covered")
  );
};

export const makeBenefitItemItems = (
  items: Partial<BenefitItemProps>[],
  benefit: string,
  plan: Plan
): BenefitItemProps[] => {
  items = items.map(item => ({
    ...item,
    benefit,
    plan,
    suppressed: shouldSuppressPlanBenefits(plan.redactions),
  }));
  return items as BenefitItemProps[];
};

export const hasOpioidTreatmentBenefit = (plan: Plan): boolean => {
  const opioidServicesCategory =
    plan.additional_supplemental_benefits?.other_benefits.find(
      c => c.category === "SB_CAT_OPIOID_TREATMENT_SERVICES"
    );

  return !!opioidServicesCategory && opioidServicesCategory.benefits.length > 0;
};

/**
 * - $x copay
 * - $x copay per visit
 * - x% coinsurance per visit
 * - x% per visit
 * - $x copay after you pay your deductible
 * - $x-y copay per visit
 * - x-y% coinsurance per visit
 * - $x or $y copay per visit
 * - x% or y% coinsurance per visit
 * - $x copay or y% coinsurance per visit
 * - $x or $y copay or z% coinsurance per visit
 * - $x copay or y-z% coinsurance per visit
 * - $x-y copay or z-n% coinsurance per visit
 * - x% or z-n% coinsurance per visit
 */
export const primaryDoctorRegexes = [
  /^([\\$\d\\.]+) (copay)$/,
  /^([\\$\d\\.]+) (copay per visit)$/,
  /^([\d\\.\\%]+) (coinsurance per visit)$/,
  /^([\d\\.\\%]+) (per visit)$/,
  /^([\\$\d\\.]+) (copay after you pay your deductible)$/,
  /^([\\$\d\\.\\-]+) (copay per visit)$/,
  /^([\d\\.\\%\\-]+) (coinsurance per visit)$/,
  /^([\\$\d\\.]+ or [\\$\d\\.]+) (copay per visit)$/,
  /^([\d\\.\\%]+ or [\d\\.\\%]+) (coinsurance per visit)$/,
  /^([\\$\d\\.]+ copay or [\d\\.\\%]+ coinsurance) (per visit)$/,
  /^([\\$\d\\.]+ or [\\$\d\\.]+ copay or [\d\\.\\%]+ coinsurance) (per visit)$/,
  /^([\\$\d\\.]+ copay or [\d\\.\\%\\-]+ coinsurance) (per visit)$/,
  /^([\\$\d\\.\\-]+ copay or [\d\\.\\%\\-]+ coinsurance) (per visit)$/,
  /^([\d\\.\\%]+ or [\d\\.\\%\\-]+) (coinsurance per visit)$/,
];

export const healthDeductibleRegexes = [
  /^([\\$\d\\.\\,]+) (annual deductible)$/i,
  /^([\\$\d\\.\\,]+ or [\\$\d\\.\\,]+) (annual deductible)$/i,
  /^([\\$\d\\.\\,]+ or [\\$\d\\.\\,]+) (in-network)$/i,
  /^([\\$\d\\.\\,]+) (in-network)$/i,
  /^([\\$\d\\.\\,]+) (out-of-network)$/i,
  /^([\\$\d\\.\\,]+ or [\\$\d\\.\\,]+) (out-of-network)$/i,
  /^([\\$\d\\.\\,]+) (in and out-of-network)$/i,
  /^([\\$\d\\.\\,]+) (per year for in-network services)$/i,
  /^([\\$\d\\.\\,]+ or [\\$\d\\.\\,]+) (per year for in-network services)$/i,
  /^([\\$\d\\.\\,]+) (per year for some in-network services and out-of-network services)$/i,
  /^[\\$\d\\.\\,]+ per year for inpatient hospital services and [\\$\d\\.\\,]+ for outpatient services with a total plan deductible of ([\\$\d\\.\\,]+) (per year from in-network and out-of-network providers)$/i,
  /^[\\$\d\\.\\,]+ or [\\$\d\\.\\,]+ per year for inpatient hospital services and [\\$\d\\.\\,]+ or [\\$\d\\.\\,]+ for outpatient services with a total plan deductible of ([\\$\d\\.\\,]+ or [\\$\d\\.\\,]+) (per year from in-network and out-of-network providers)$/i,
];

export const getHealthDeductible = (
  plan: Plan
): { cost: string; label: string } => {
  const healthDeductible = { cost: "", label: "" };
  const { annual_deductible } = plan;

  if (annual_deductible === "") {
    return { cost: "N/A", label: "" };
  }

  healthDeductibleRegexes.forEach(regex => {
    const match = String(annual_deductible).replace(/\.$/, "").match(regex);

    if (match && match.length === 3) {
      healthDeductible.cost = match[1];
      healthDeductible.label = match[2];
    }
  });

  if (!healthDeductible.cost) {
    healthDeductible.cost = formatCost(annual_deductible);
    healthDeductible.label = "";
  }

  return healthDeductible;
};

export const formatCost = (value: string | number): string => {
  let numberValue;

  if (typeof value === "string") {
    // @see https://github.com/eslint/eslint/issues/11103#issuecomment-439916446
    // double-escape the decimal to avoid eslint error, same replacement result
    numberValue = Number(value.replace(/[^\d\\.]/gm, ""));
  } else {
    numberValue = value;
  }

  return $$(numberValue);
};

export const getInNetworkPrimaryDoctorCost = (
  plan: Plan,
  t: (str: string) => string
): { cost: string; label: string } => {
  const cost = { cost: t("suppressed_plan_benefit"), label: "" };

  if (shouldSuppressPlanBenefits(plan.redactions)) {
    return cost;
  }

  const benefit = getBenefitObject(plan, "doctor_visits");

  if (benefit) {
    const service = getBenefitService(benefit, "primary");

    if (service) {
      const { network_costs } = service;
      const inNetwork =
        network_costs["NETWORK_TYPE_NA"] ||
        network_costs["NETWORK_TYPE_IN_NETWORK"];

      if (inNetwork) {
        const { cost_share } = inNetwork;

        primaryDoctorRegexes.forEach(regex => {
          const match = cost_share.match(regex);

          if (match && match.length === 3) {
            cost.cost = match[1]; // $10
            cost.label = match[2]; // copay per visit
          }
        });
      }
    }
  }

  return cost;
};

/**
 * Get all of a plan's `benefit_service` keys for a particular `package_benefit`
 * @see MCT-8666: `package_benefits` should be an enum of all optional keys,
 * each with `benefit_services`
 */
export const getPackageBenefitServiceKeys = ({
  plan,
  package_benefit,
}: {
  plan: Plan;
  package_benefit: string;
}) =>
  Object.keys(plan.package_benefits[package_benefit]?.benefit_services || {});

/**
 * Each of a `plan`s `package_benefits`' `benefit_services` should have a key
 * of all-caps prefixed by `"SERVICE_"`
 *
 * The service name of any of a `plan`'s `package_benefits` is just
 * the benefit's key, lowercased, minus the prefix `SERVICE_`
 *
 * E.g. for `SERVICE_FREE_ICE_CREAM`, the service name is `free_ice_cream`
 */
export const getBenefitServiceName = (key: string) =>
  key.toLowerCase().replace(/^service_/, "");

/**
 * Each of a `plan`s `package_benefits`' `benefit_services` should have a key
 * of all-caps prefixed by `"SERVICE_"`
 *
 * Translation keys for each `benefit_service` should be that key, lowercased,
 * minus that prefix `SERVICE_`, with the new prefix `"plan_terms."` added.
 *
 * So for `SERVICE_FREE_ICE_CREAM`, the translation key should be `plan_terms.free_ice_cream`
 */
export const getBenefitServiceTranslationKey = (name: string) =>
  `plan_terms.${getBenefitServiceName(name)}`;
