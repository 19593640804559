import { useLocation } from "react-router";
import { useEffect, useMemo, useState } from "react";
import routes from "../app/routes";
import { getSLSCode } from "./beneficiaryInfoHelpers";
import { useAppContext } from "./context-hooks";
import { Beneficiary } from "../@types";

/**
 * Confirms whether a LI bene is a CSR
 */
export const getBeneIsCsr = (beneficiary: Beneficiary | undefined): boolean =>
  !!(beneficiary?.csr_id || beneficiary?.meta_data.is_csr);

/**
 * Confirms whether a session is a CSR session, based on LI bene CSR-specific
 * field values or whether the bene is on the CSR landing page and/or has a `code`
 * query param to pass to `/beneinfo/login-csr`
 */
export const getIsCsrSession = ({
  is_csr,
  csr_id,
  pathname,
  search,
}: {
  is_csr?: boolean;
  csr_id?: string;
  pathname: ReturnType<typeof useLocation>["pathname"];
  search: ReturnType<typeof useLocation>["search"];
}): boolean => {
  const code = getSLSCode(search);
  const isCsrLanding = pathname === routes.csrLanding;
  return !!(is_csr || code || csr_id || isCsrLanding);
};

/**
 * Hook that encapsulates context needed to call `getIsCsrSession` from inside
 * component code.
 *
 * Checks for whether this is a CSR session, before or after authentication.
 *
 * Takes no parameters.
 *
 * @see {@link getIsCsrSession}
 */
export const useIsCsrSession = (): boolean => {
  const [wasCsrSession, setWasCsrSession] = useState(false);
  const {
    state: { beneficiary },
  } = useAppContext();
  const { pathname, search } = useLocation();
  const is_csr = beneficiary?.meta_data.is_csr;
  const csr_id = beneficiary?.csr_id;
  const isCsr = useMemo(
    () =>
      wasCsrSession || getIsCsrSession({ is_csr, csr_id, pathname, search }),
    [is_csr, csr_id, wasCsrSession, pathname, search]
  );
  // Because logout clears `AppState`, we can lose information about CSR session
  // before final routing. This effect hangs onto the correct boolean value
  // for a CSR up until that last logout routing happens (different for CSRs than
  // regular benes)
  useEffect(() => {
    if (getIsCsrSession({ is_csr, csr_id, pathname, search })) {
      setWasCsrSession(true);
    }
  }, [is_csr, csr_id, pathname, search]);
  return isCsr;
};
