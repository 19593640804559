import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { InfoCircleOutlineIcon } from "@cmsgov/ds-medicare-gov";
import { AppContext } from "../../app/store";
import { useTranslate } from "../../helpers";
import { HelpDrawerWrapper } from "../../components";
import { AnalyticsActionType } from "../../app/contexts/Analytics/types";
import routes from "../../app/routes";
import { createSimpleHelpDrawerAnalytics } from "../../helpers/simpleHelpDrawerAnalytics";

export interface PlanTypeHelpDrawerProps {
  newToMedicare?: boolean;
}
export const PlanTypeHelpDrawer: React.FC<PlanTypeHelpDrawerProps> = ({
  newToMedicare,
}) => {
  const t = useTranslate();
  const { dispatch } = useContext(AppContext);
  const helpDrawerToggleText = t("coverage_selector.help_drawer.toggle");
  const helpDrawerTitle = t("coverage_selector.help_drawer.title");
  const needHelpText = newToMedicare
    ? t("coverage_selector.ntm.help_link_text")
    : t("coverage_selector.help_drawer.help_link_text");
  const needHelpLinkUrl = routes.coverageWizard.options;
  const helperDrawerAnalytics = createSimpleHelpDrawerAnalytics({
    toggleText: helpDrawerToggleText,
    heading: helpDrawerTitle,
  });
  return (
    <HelpDrawerWrapper
      toggleText={
        <>
          <span className="ds-u-margin-right--1">{helpDrawerToggleText}</span>
          <InfoCircleOutlineIcon />
        </>
      }
      toggleClassName="mct-c-coverage-selector-v2__help-drawer-toggle"
      mctAnalytics={helperDrawerAnalytics}
      drawerTitle={helpDrawerTitle}
    >
      <div>
        {/** // * Medicare Advantage Plan (Part C) */}
        <h4 className="ds-u-margin-bottom--0">
          {t("coverage_selector.help_drawer.part_c.sub_heading")}
        </h4>
        <p className="ds-u-margin-top--1">
          {t("coverage_selector.help_drawer.part_c.body")}
        </p>
        {/** // * Medicare drug plan (Part D) */}
        <h4 className="ds-u-margin-bottom--0">
          {t("coverage_selector.help_drawer.part_d.sub_heading")}
        </h4>
        <p className="ds-u-margin-top--1">
          {t("coverage_selector.help_drawer.part_d.body")}
        </p>
        {/** // * Medigap policy */}
        <h4 className="ds-u-margin-bottom--0">
          {t("coverage_selector.help_drawer.medigap.sub_heading")}
        </h4>
        <p className="ds-u-margin-top--1">
          {t("coverage_selector.help_drawer.medigap.body")}
        </p>
        <Link
          to={needHelpLinkUrl}
          onClick={() => {
            dispatch({
              type: AnalyticsActionType.SEND_INTERNAL_LINK_CLICKED_EVENT,
              settings: {
                linkText: needHelpText,
                linkUrl: needHelpLinkUrl,
              },
            });
          }}
        >
          {needHelpText}
        </Link>
      </div>
    </HelpDrawerWrapper>
  );
};
